import React, { FC, useEffect } from 'react';
import { Button } from 'uibuilder/button';
import { CustomNodeElementProps } from 'react-d3-tree';
import { TreeNodeProps as TreeNodeDatum } from 'shared/uibuilder/Tree/treeHelpers';
import classnames from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/RemoveOutlined';

export type HierarchyPointNode = {
  x: number;
  y: number;
  data: any;
};

interface TreeNodeProps extends CustomNodeElementProps {
  nodeChildren?: (nodeData: TreeNodeDatum, depth: number) => JSX.Element;
  onClick?: (nodeData: TreeNodeDatum) => void;
  onToggleNode: (hierarchyPointNode: HierarchyPointNode) => void;
  onChangeNode: (hierarchyPointNode: HierarchyPointNode) => void;
  checkNodeState: (rd3tNodeProps: CustomNodeElementProps) => void;
}

const TreeNode: FC<TreeNodeProps> = ({
  nodeDatum,
  toggleNode,
  nodeChildren,
  onClick = () => {},
  hierarchyPointNode,
  onToggleNode,
  onChangeNode,
  ...props
}) => {
  useEffect(() => {
    onChangeNode(hierarchyPointNode);
    // Suppressed warnings because we only need to call useEffect callback if hierarchyPointNode is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hierarchyPointNode]);

  return (
    <g>
      <foreignObject x="-150px" height="175px" width="300px" y="-50px" style={{ overflow: 'initial' }}>
        <div className="tree-node-wrapper">
          <div
            className={classnames(
              'tree-node',
              {
                'main-node': !hierarchyPointNode.depth,
                'secondary-node': hierarchyPointNode.depth === 1,
              },
              `tree-node--${(nodeDatum as TreeNodeDatum).nodeType?.toLowerCase()}`,
            )}
            role="button"
            tabIndex={0}
            onKeyPress={() => {}}
            onClick={e => {
              if ((e.target as HTMLElement).matches('a')) {
                return;
              }

              onClick(nodeDatum as TreeNodeDatum);
            }}
          >
            {nodeChildren && nodeChildren(nodeDatum as TreeNodeDatum, hierarchyPointNode.depth)}
          </div>
          {!!nodeDatum.children?.length && (
            <Button
              disableRipple
              type="button"
              className="tree-node-toggle"
              onClick={(e: any) => {
                e.stopPropagation();
                toggleNode();
                onToggleNode(hierarchyPointNode);
              }}
            >
              {/* eslint-disable-next-line no-underscore-dangle */}
              {nodeDatum.__rd3t.collapsed ? <AddIcon /> : <RemoveIcon />}
            </Button>
          )}
        </div>
      </foreignObject>
    </g>
  );
};

export default TreeNode;
