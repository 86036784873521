import { useHistory } from 'react-router-dom';
import { parseJson } from 'shared/uibuilder/list/builder/useListParams';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { Location } from 'history';
import { DefaultListParams, ListParams } from '../ListContext';
import { ASC, DESC } from 'shared/uibuilder/list/baseListHelper';

const SORT_ORDERS = [ASC, DESC];
const DEFAULT_SORT_ORDER = DESC;

export const getUrlStorageParams = (location: Location): ListParams => {
  const defineOrder = (order: string, sortBy: string | null) => {
    if (SORT_ORDERS.includes(order)) {
      return order;
    } else {
      return sortBy ? DEFAULT_SORT_ORDER : '';
    }
  };

  const currentSearchParams = new URLSearchParams(location.search) || {};
  const order = currentSearchParams.get('sortOrder') || '';
  const orderBy = currentSearchParams.get('sortBy');
  const formattedOrder = defineOrder(order, orderBy);

  return {
    sort: {
      field: orderBy || undefined,
      order: formattedOrder,
    },
    page: (currentSearchParams.get('page') && Number(currentSearchParams.get('page') || undefined) - 1) || 0,
    filter: currentSearchParams.get('filter') && parseJson(currentSearchParams.get('filter') || ''),
  };
};

const getInitialParams = (
  location: Location,
  { defaultSortField, defaultSortOrder, page, defaultFilter }: DefaultListParams,
): ListParams => {
  const currentSearchParams = getUrlStorageParams(location);

  return {
    sort: {
      field: currentSearchParams.sort?.field || defaultSortField,
      order: currentSearchParams.sort?.order || defaultSortOrder,
    },
    page: currentSearchParams.page || page || 0,
    filter: currentSearchParams.filter || defaultFilter,
  };
};

const useListUrlStoring = ({
  defaultSortField = '',
  defaultSortOrder = '',
  defaultFilter = {},
  page: defaultPage = 0,
}: DefaultListParams = {}) => {
  const { location, ...history } = useHistory();

  const [params] = useState(
    getInitialParams(location, {
      defaultFilter,
      defaultSortField,
      defaultSortOrder,
      page: defaultPage,
    }),
  );

  const updateParamsInUrl = ({ sort = {}, filter, page }: ListParams) => {
    const searchParams = [];
    if (sort.field && sort.order) {
      searchParams.push(`sortBy=${sort.field}&sortOrder=${sort.order}`);
    }

    if (filter && !isEmpty(filter)) {
      searchParams.push(`filter=${JSON.stringify(filter)}`);
    }

    if (page && page > 0) {
      searchParams.push(`page=${page + 1}`);
    }

    const searchString = searchParams.length ? `?${searchParams.join('&')}` : '';

    if (location.search !== searchString) {
      history.replace({
        search: searchParams.length ? `?${searchParams.join('&')}` : '',
      });
    }
  };

  return {
    params,
    updateParamsInUrl,
  };
};

export default useListUrlStoring;
