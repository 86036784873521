// libs
import React from 'react';
// components
import { Route } from 'shared/routing';
import Page403 from 'shared/authorization//Page403';
// services
import useAuthorization, { Permission } from 'shared/authorization/authorizationService';

type ProtectedRouteProps = {
  path?: string;
  exact?: boolean;
  permissionToCheck: Permission | Permission[];
  children: React.ReactElement;
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { isGranted } = useAuthorization();
  const { permissionToCheck, exact = false, children } = props;

  return (
    <Route exact={exact} {...props}>
      {isGranted(permissionToCheck) ? children : <Page403 />}
    </Route>
  );
};

export default ProtectedRoute;
