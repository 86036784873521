// libs
import { useState } from 'react';
import shortid from 'shortid';
// services
import { getFilePreview, getFileType } from 'artifact/artifactService';

const useUploadingQueueService = () => {
  /**
   * Stores map unique id => uploading object.
   *
   * Uploading object's fields:
   * - loading progress (0-100)
   * - file type (image/doc/..)
   * - imagePreview (if it's image, base64 string, as we had in previous implementation)
   */
  const [uploadingQueue, setUploadingQueue] = useState<any>([]);

  /**
   * Returns array of the Uploading objects
   */
  const getObjectsInQueue = () => {
    return uploadingQueue;
  };

  /**
   * Adds file to the queue with initial loading progress = 0 and returns some unique id
   */
  const addToUploadingQueue = async (fileObject: File) => {
    const temporaryId = shortid.generate();

    const filePreview = await getFilePreview(fileObject);
    const fileType = getFileType(fileObject);

    const controller = new AbortController();
    const { signal } = controller;

    setUploadingQueue((prevUploadingQueue: any) => [
      ...prevUploadingQueue,
      {
        filePreview,
        name: fileObject.name,
        title: fileObject.name,
        type: fileType,
        controller,
        id: temporaryId,
      },
    ]);
    return { temporaryId, signal };
  };

  const removeFromUploadingQueue = (id: string) => {
    setUploadingQueue((prevUploadingQueue: any[]) => {
      const loadingArtifactObjects = prevUploadingQueue.find(obj => obj.id === id);

      if (loadingArtifactObjects && loadingArtifactObjects.loadingProgress !== 100) {
        loadingArtifactObjects.controller.abort();
      }
      return [...prevUploadingQueue.filter((preview: { id: string }) => preview.id !== id)];
    });
  };

  /**
   * Only replace loading progress for some file.
   */
  const modifyLoadingProgress = (id: any, loadingProgress: { loaded: any; total: any }) => {
    const { loaded, total } = loadingProgress;
    const loadingPercentages = ((loaded / total) * 100).toFixed(0);

    setUploadingQueue((prevUploadingQueue: any[]) => [
      ...prevUploadingQueue.map(item => {
        if (item.id === id) {
          return {
            ...item,
            loadingProgress: loadingPercentages,
          };
        }

        return item;
      }),
    ]);
  };

  return {
    addToUploadingQueue,
    removeFromUploadingQueue,
    modifyLoadingProgress,
    getObjectsInQueue,
    setUploadingQueue,
  };
};

export default useUploadingQueueService;
