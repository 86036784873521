/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { Checkbox, TextInput } from 'shared/uibuilder/form/input';
import useFeatureToggle, { Features } from 'featuretoggle';
import NotDismissedEmployeeInput from 'erp/employee/shared/input/NotDismissedEmployeeInput';
import RbsNodeInput from 'erp/rbs/shared/input/RbsNodeInput';
import BudgetInput from 'erp/budget/shared/input/BudgetInput';
import BudgetsInput from 'erp/budget/shared/input/BudgetsInput';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const ServiceForm = () => {
  const { data } = useFormContext();
  const forbidProjectAssignmentsEnabled = useFeatureToggle().isFeatureEnabled(Features.FORBID_PROJECT_ASSIGNMENTS);

  const getStaffingCoordinatorLabel = () => {
    const employee = data?.staffingCoordinator;

    if (!employee) {
      return undefined;
    }

    const alias = employee.alias || '';
    const firstName = employee.nameEn?.firstName || '';
    const lastName = employee.nameEn?.lastName || '';

    return `${alias} ${firstName} ${lastName}`;
  };

  return (
    <FormSection title="Service information">
      <FormRow>
        <TextInput source="name" label="Name" />
        <NotDismissedEmployeeInput source="ownerId" label="Owner" nameSource="ownerName" />
        <RbsNodeInput source="rbsTreeNodeId" nameSource="rbsTreeNodeName" label="RBS Node" errorSource="rbsTreeNode" />
      </FormRow>
      <FormRow>
        <BudgetInput
          source="defaultBudgetId"
          nameSource="defaultBudget.name"
          label="Default Budget"
          searchString="name"
          filter={{ defaultableBudget: true, notArchived: true }}
        />
        <BudgetsInput filter={{ defaultableBudget: true, notArchived: true }} source="availableBudgets" />
      </FormRow>
      <FormRow>
        <EmployeeInput
          source="staffingCoordinatorAlias"
          label="Staffing Coordinator"
          initialLabel={getStaffingCoordinatorLabel()}
        />
      </FormRow>
      <FormRow>
        <Checkbox
          source="assignmentForbidden"
          label="Do not allow assignment creation"
          isVisible={forbidProjectAssignmentsEnabled}
        />
      </FormRow>
    </FormSection>
  );
};

export default ServiceForm;
