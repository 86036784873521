/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useCandidateService from 'erp/candidate/shared/candidateService';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const deleteMessage = 'Do you really want to delete this candidate?';

const DeleteCandidateButton = () => {
  const { id: candidateId } = useParams<Dictionary<string>>();
  const { deleteById } = useCandidateService();
  const { getListUrl } = useCandidateUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(candidateId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Candidate has been successfully deleted.',
        errorMessage: `Can't delete candidate`,
      }}
    />
  );
};

export default DeleteCandidateButton;
