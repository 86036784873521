/* istanbul ignore file */
import React from 'react';
import Page404 from 'shared/uibuilder/Page404';
import { LeaveRouter } from 'erp/leave';
import { Interview } from 'erp/interview';
import { PayrollRouter } from 'erp/payroll';
import { EmployeesRouter } from 'erp/employee';
import { CandidateRouter } from 'erp/candidate';
import { ContractsRouter } from 'erp/contracts';
import { WorkingHoursRouter } from 'erp/workinghours';
import { Route, Router, RoutingSwitch } from 'shared/routing';
import { CompetencyRouter } from 'erp/qualification/competency';
import { VacancyRouter } from 'erp/recruitment/vacancy';
import { ProjectRouter } from 'erp/project';
import { RbsRouter } from 'erp/rbs';
import { AssignmentRouter } from 'erp/assignment';
import { BudgetRouter } from 'erp/budget';
import { BudgetReportRouter } from 'erp/budget/report';
import { SkillBasedQueueRouter } from 'erp/skillbasedqueue';
import { VacancyPublicationRouter } from 'erp/recruitment/vacancy/publication';
import { PerformanceReportRouter } from 'erp/performanceReport';
import { ServiceRouter } from 'erp/service';
import { CostAccountingRouter } from 'erp/costaccounting';
import { LedgerRouter } from 'financialAnalytic/ledger';
import { JournalEntryRouter } from 'financialAnalytic/journalEntry';
import { InvoiceRouter } from 'financialAnalytic/invoice';
import { CustomerInvoiceRouter } from 'financialAnalytic/customerInvoices';
import { AssetRouter } from 'financialAnalytic/assets';
import { ExpenseRouter } from 'financialAnalytic/expenses';
import { PurchaseOrderRouter } from 'financialAnalytic/purchaseOrder';
import { NewCompetencyRouter } from 'erp/qualification/newCompetency';
import { ApplicationsRouter } from 'erp/recruitment/applications';
import { GeneratedPayrollsRouter } from 'erp/payroll/generatedPayrolls';
import { PayrollDistributionRouter } from 'erp/payroll/payrollDistribution';
import { ReconciliationRouter } from 'erp/costaccounting/reconcilation';
import { WasteRouter } from 'erp/costaccounting/waste';
import { AccountingCycleRouter } from 'erp/costaccounting/accountingCycle';
import { TrainingsRouter } from 'erp/trainings';
import { TrainingInstancesRouter } from './trainings/trainingInstances';
import { CorporateEventRouter } from 'financialAnalytic/corporateEvents';
import { RoomRouter } from 'erp/room';
import OfficeRouter from 'erp/office/OfficeRouter';
import HiringRouter from './recruitment/hiring/HiringRouter';
import RecruitingDashboardRouter from './recruitment/dashboard/RecruitingDashboardRouter';

const ERP_PATHS: (string | string[])[] = [
  LeaveRouter.getRouterPath(),
  Interview.getRouterPath(),
  PayrollRouter.getRouterPath(),
  CandidateRouter.getRouterPath(),
  VacancyRouter.getRouterPath(),
  HiringRouter.getRouterPath(),
  RecruitingDashboardRouter.getRouterPath(),
  VacancyPublicationRouter.getRouterPath(),
  EmployeesRouter?.getRouterPath(),
  ContractsRouter.getRouterPath(),
  NewCompetencyRouter.getRouterPath(),
  CompetencyRouter.getRouterPath(),
  WorkingHoursRouter.getRouterPath(),
  ProjectRouter.getRouterPath(),
  ServiceRouter.getRouterPath(),
  RbsRouter.getRouterPath(),
  AssignmentRouter.getRouterPath(),
  BudgetRouter.getRouterPath(),
  BudgetReportRouter.getRouterPath(),
  CostAccountingRouter.getRouterPath(),
  SkillBasedQueueRouter.getRouterPath(),
  PerformanceReportRouter.getRouterPath(),
  LedgerRouter.getRouterPath(),
  JournalEntryRouter.getRouterPath(),
  InvoiceRouter.getRouterPath(),
  CustomerInvoiceRouter.getRouterPath(),
  AssetRouter.getRouterPath(),
  CorporateEventRouter.getRouterPath(),
  ExpenseRouter.getRouterPath(),
  PurchaseOrderRouter.getRouterPath(),
  GeneratedPayrollsRouter.getRouterPath(),
  ApplicationsRouter.getRouterPath(),
  PayrollDistributionRouter.getRouterPath(),
  ReconciliationRouter.getRouterPath(),
  WasteRouter.getRouterPath(),
  AccountingCycleRouter.getRouterPath(),
  TrainingsRouter.getRouterPath(),
  TrainingInstancesRouter.getRouterPath(),
  OfficeRouter.getRouterPath(),
  RoomRouter.getRouterPath(),
];

const ErpRouter: Router = () => (
  <RoutingSwitch>
    <LeaveRouter />
    <PayrollRouter />
    <CandidateRouter />
    <TrainingsRouter />
    <TrainingInstancesRouter />
    <VacancyRouter />
    <HiringRouter />
    <RecruitingDashboardRouter />
    <VacancyPublicationRouter />
    <EmployeesRouter />
    <ContractsRouter />
    <WorkingHoursRouter />
    <SkillBasedQueueRouter />
    <BudgetReportRouter />
    <CostAccountingRouter />
    <NewCompetencyRouter />
    <CompetencyRouter />
    <ProjectRouter />
    <ServiceRouter />
    <RbsRouter />
    <BudgetRouter />
    <AssignmentRouter />
    <PerformanceReportRouter />
    <LedgerRouter />
    <JournalEntryRouter />
    <InvoiceRouter />
    <CustomerInvoiceRouter />
    <AssetRouter />
    <CorporateEventRouter />
    <ExpenseRouter />
    <PurchaseOrderRouter />
    <GeneratedPayrollsRouter />
    <ApplicationsRouter />
    <PayrollDistributionRouter />
    <ReconciliationRouter />
    <WasteRouter />
    <AccountingCycleRouter />
    <OfficeRouter />
    <RoomRouter />
    <Interview.Router />
    <Route component={Page404} />
  </RoutingSwitch>
);

ErpRouter.getRouterPath = () => ERP_PATHS.flat(1);

export default ErpRouter;
