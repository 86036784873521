/* istanbul ignore file */
import React from 'react';
import { DateField, TextField } from 'shared/uibuilder/field';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import DocuSignRecipientsBox from 'shared/docusign/envelope/DocuSignRecipientsBox';
import Typography from 'uibuilder/Typography';
import TableBodyWithGrouping from 'shared/uibuilder/list/layout/DataGridLayout/TableBodyWithGrouping';
import { DEFAULT_DATE_TIME_FORMAT } from 'shared/uibuilder/dateService';
import BadgeField from 'shared/uibuilder/field/BadgeField';
import DocuSignEnvelopeStatusBadge from 'shared/docusign/envelope/badge/DocuSignEnvelopeStatusBadge';

const COUNT_TABLE_TD = 3;

export const DocuSignEnvelopeStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  DECLINED: 'DECLINED',
};

export const DocuSignEnvelopeStatusAlias = {
  [DocuSignEnvelopeStatus.IN_PROGRESS]: 'In progress',
  [DocuSignEnvelopeStatus.COMPLETED]: 'Signed',
  [DocuSignEnvelopeStatus.DECLINED]: 'Declined',
};

interface DocuSignEnvelopeBoxProps {
  source: string;
}

const DocuSignEnvelopeBox = ({ source }: DocuSignEnvelopeBoxProps) => {
  const { data: showData } = useShowContext();
  const envelopes = showData.getValueBySource(source);
  const totalElements = envelopes ? envelopes.length : 0;

  return (
    <ControlledList data={{ items: envelopes, totalElements, totalPages: 1 }}>
      <DataGridLayout
        showTotal={false}
        tableBodyComponent={(props: any) => (
          <TableBodyWithGrouping
            {...props}
            childrenSource="recipients.signers"
            toggleSource="docuSignEnvelopeId"
            CustomChildTemplate={
              <tr>
                <td colSpan={COUNT_TABLE_TD}>
                  <Typography variant="h5" className="c-title" sx={{ mb: '5px', mt: '10px' }}>
                    Signers Details
                  </Typography>
                  <DocuSignRecipientsBox source="recipients.signers" />
                </td>
              </tr>
            }
          />
        )}
      >
        <TextField source="docuSignEnvelopeId" label="DocuSign ID" width="25%" />
        <BadgeField badges={[<DocuSignEnvelopeStatusBadge source="envelopeStatus" />]} label="Status" width="25%" />
        <DateField source="updatedAt" label="Updated At" width="25%" dateFormat={DEFAULT_DATE_TIME_FORMAT} />
      </DataGridLayout>
    </ControlledList>
  );
};

export default DocuSignEnvelopeBox;
