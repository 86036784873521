import React from 'react';
import useGetData from 'shared/useGetData';
import useForm, { FormHelperProps } from 'shared/uibuilder/form/formHelper';
import { FormFieldsData, FormProvider } from 'shared/uibuilder/form/FormContext';
import Loading from 'shared/uibuilder/Loading';
import { Prompt, useLocation } from 'react-router-dom';
import { useBaseValidationMapping } from 'validation-schema-library';
import { ResourceData } from '../../crud/baseCrudService';

const isPathnameChanged = (currentPathname: string, newPathname: string) => currentPathname !== newPathname;

export interface FormTemplateProps extends FormHelperProps {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  getDataFunc?: Nullable<() => Promise<ResourceData>>;
}

/**
 * Base component for all the forms. Includes common behavior of all the forms.
 *
 * @param children
 * @param initialData may be used to pass default values for update forms
 * @param getValidationSchema - promise that returns validation schema
 * @param fieldsExceptions --  map with values resolving exceptions. For example. {'status': 'QUALIFIED'} will
 *   mean that $status variable will always be resolved as 'QUALIFIED'
 * @param submitFormFunc the function which saves form data
 * @param afterSubmit - map with redirect and message
 * @param getDataFunc the function which loads the data. if passed, the component shows loaded until the data is loaded by this func
 * @returns {*}
 * @constructor
 */
const FormTemplate = ({
  children,
  initialData,
  getValidationSchemaFunc,
  handleErrorsOnSubmit,
  isNeedToHandleErrorsOnSubmit = true,
  fieldsExceptions,
  isCreateRequest = true,
  submitFormFunc,
  afterSubmit,
  getDataFunc = null,
  handleDirty = true,
  errorsMap = {},
  messageContext,
}: FormTemplateProps) => {
  const baseErrorMap = useBaseValidationMapping();
  const { isDirty, ...contextValue } = useForm({
    initialData,
    afterSubmit,
    submitFormFunc,
    handleErrorsOnSubmit,
    isNeedToHandleErrorsOnSubmit,
    getValidationSchemaFunc,
    fieldsExceptions,
    handleDirty,
    isCreateRequest,
    errorsMap: {
      ...baseErrorMap,
      ...errorsMap,
    },
    messageContext,
  });

  const { pathname } = useLocation();

  const setData = (data: FormFieldsData) => {
    const { setFormData } = contextValue;

    if (data) {
      setFormData(data);
    }
  };

  const { loading } = useGetData(getDataFunc, setData, getDataFunc !== null);

  if (!loading) {
    return (
      <FormProvider
        value={{
          isDirty,
          ...contextValue,
        }}
      >
        <Prompt
          when={isDirty}
          message={newLocation => {
            // if false is returned -> popup won't be displayed.
            return isPathnameChanged(pathname, newLocation.pathname)
              ? 'Are you sure you want to go away? Unsaved data will be lost.'
              : false;
          }}
        />
        <>{children}</>
      </FormProvider>
    );
  }
  return <Loading />;
};

export default FormTemplate;
