/* istanbul ignore file */
import vacancyValidation from 'erp/recruitment/vacancy/createupdate/vacancyValidation';
import { useBusinessEngineApi, useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import useOfficeService, { OFFICE_NAMES } from 'erp/employee/office/officeService';
import usePositionService from 'erp/position/positionService';
import { EMPLOYEE_WORKPLACE_LOCATION } from 'erp/employee/employeeService';
import {
  EMPLOYEE_CONTRACT_ASSIGMENT_TYPES,
} from 'erp/employee/contracts/shared/input/EmployeeContractAssignmentTypeRadios';
import {
  EMPLOYEE_CONTRACT_TYPES,
} from 'erp/employee/contracts/shared/input/EmployeeContractTypeDropdown';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import useDateTimeService from 'shared/uibuilder/dateService/useDateTimeService';
import useHiringValidationOneStop
  from 'erp/recruitment/hiring/createupdate/OneStop/hiringValidationOneStop';
import moment from 'moment';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

const convertToOptions = (map: object) => {
  return Object.entries(map).map(([alias, text]) => ({ value: alias, label: text }));
};

export const VACANCY_PATH = '/vacancies';
export const VACANCY_EMPLOYMENT_TYPES = convertToOptions(EMPLOYEE_CONTRACT_ASSIGMENT_TYPES);

export const RELOCATION_OPTIONS = {
  REQUIRED: 'Required',
  OPTIONAL: 'Optional',
  NOT_APPLICABLE: 'Not applicable',
};

export const VACANCY_HAS_PROBATION_OPTIONS = {
  true: 'Yes',
  false: 'No',
};

export const VACANCY_WORKPLACE_LOCATIONS = convertToOptions(EMPLOYEE_WORKPLACE_LOCATION);

export const VACANCY_STATUSES = {
  DRAFT: 'Draft',
  OPEN: 'Open',
  CLOSED: 'Closed',
  REJECTED: 'Rejected',
};

export const WORKING_SCHEDULE = {
  STANDARD: 'Standard',
  NON_STANDARD: 'Non-standard',
};

export const VACANCY_DUE_DATE_CONSTANT = {
  ASAP: 'ASAP',
  TWO_MONTHS: 'TWO_MONTHS',
  TWO_PLUS: 'TWO_PLUS',
};

export const VACANCY_DUE_DATE = {
  ASAP: 'ASAP',
  TWO_MONTHS: '1-2 months',
  TWO_PLUS: '2+ months',
};

export const COMPETENCY_LEVEL = {
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
  L4: 'L4',
  L5: 'L5',
  L6: 'L6',
  'N/A': 'N/A',
};

export const VACANCY_EMPLOYEE_CONTRACT_TYPES_NO_INTERNSHIP: any = Object.fromEntries(
  Object.entries(EMPLOYEE_CONTRACT_TYPES).filter(([key]) => key !== 'INTERNSHIP'),
);

export const VACANCY_EMPLOYEE_CONTRACT_TYPES = convertToOptions(VACANCY_EMPLOYEE_CONTRACT_TYPES_NO_INTERNSHIP);

export const ONETIME_VACANCY_TYPE = 'ONETIME';
export const RECURRING_VACANCY_TYPE = 'RECURRING';

export const VACANCY_TYPES = { [ONETIME_VACANCY_TYPE]: 'One Time', [RECURRING_VACANCY_TYPE]: 'Recurring' };
export const VACANCY_POSITIONS = Object.fromEntries(Array.from({ length: 10 }, (_, i) => [i + 1, (i + 1).toString()]));

export const READ_VACANCIES_LIST = 'READ_VACANCIES_LIST';
export const DISPLAY_UPDATE_BUTTON = 'FE_UPDATE_VACANCY';
export const READ_VACANCY = 'READ';
export const CREATE_VACANCY = 'CREATE_VACANCY';
export const UPDATE_VACANCY = 'UPDATE';
export const DELETE_VACANCY = 'DELETE';
export const UPDATE_VACANCY_MANAGER = "UPDATE_VACANCY_MANAGER";

type VacancyDueDate = (typeof VACANCY_DUE_DATE_CONSTANT)[keyof typeof VACANCY_DUE_DATE_CONSTANT];

export interface VacancyDetailsType {
  office?: object;
  assignmentType?: string[];
  contractType?: string[];
  relocationOption?: string;
  hasProbation?: boolean;
  workplaceLocation?: string[];
  workingSchedule?: string;
}

export interface VacancyDescriptionType {
  projectText?: string;
  responsibilities?: object[];
  requirements?: object[];
  optionalRequirements?: object[];
  personality?: object[];
}

export interface VacancyType {
  vacancyDetails?: VacancyDetailsType[];
  description?: VacancyDescriptionType;
}

const useVacancyService = () => {
  const { getCurrentDate, formatDateForAPI } = useDateService();
  const { getCurrentTimezoneDateInUtc } = useDateTimeService();
  const defaultExpirationDate = getCurrentTimezoneDateInUtc(getCurrentDate().add(6, 'months'));

  const singleResourceUrl = '/vacancies/:id';
  const {
    invalidateCache,
    getById: baseGetById,
    create: baseCreate,
    update: baseUpdate,
    ...baseCrudRequests
  } = useCrudService({
    singleResourceUrl,
    listResourceUrl: '/vacancies',
    apiService: useKernelApi,
  });
  const { sendPatchRequest, sendPutRequest } = useKernelApi('/vacancies/');

  const { sendPostRequest } = useBusinessEngineApi();

  const { findAll } = useOfficeService();

  const getOfficeOptions = async () => {
    const offices = await findAll();

    return offices.map((office: any) => ({
      value: office.id,
      label: OFFICE_NAMES[office.name],
    }));
  };

  const getOfficeOption = (offices: any[], label: string) =>
    offices.filter((office: any) => office.label === label)[0].value;

  const { findAll: findAllPositions } = usePositionService();

  const getPositionOptions = async () => {
    const positions = await findAllPositions();

    const vacancyPositions = positions.reduce((specializations: Dictionary<string>, position: Dictionary<string>) => {
      return { ...specializations, [position.vacancyAlias]: position.name };
    }, {});
    vacancyPositions.OTH = 'Other';

    return vacancyPositions;
  };

  const mapVacancy = async (vacancy: any) => {
    const vacancyPositions = await getPositionOptions();

    return {
      ...vacancy,
      name: vacancy.name ? vacancy.name : vacancyPositions[vacancy.aliasPrefix],
      competencyLevel: vacancy.competencyLevel === COMPETENCY_LEVEL['N/A'] ? null : vacancy.competencyLevel,
      vacancyDetails: vacancy.vacancyDetails
        ? vacancy.vacancyDetails.map((details: any) => {
            return {
              office: details.office,
              assignmentType: details.assignmentType,
              workingSchedule: details.workingSchedule,
              workingScheduleDescription: details.workingScheduleDescription,
              workplaceLocation: details.workplaceLocation,
              contractType: details.contractType,
              hasProbation: details.hasProbation,
              relocationOption: details.relocationOption,
            };
          })
        : [],
    };
  };

  const mapHiring = async (data: any) => {
    const mappedVacancy = await mapVacancy(data);

    const isExistingVacancy = mappedVacancy.useExistingVacancy;
    const calculateDueDate = (isAsap: boolean, targetDate: string): VacancyDueDate => {
      if (isAsap) return VACANCY_DUE_DATE_CONSTANT.ASAP;

      const currentDate = getCurrentDate();
      const targetDateMoment = moment(targetDate);

      return targetDateMoment.diff(currentDate, 'days') <= 60
        ? VACANCY_DUE_DATE_CONSTANT.TWO_MONTHS
        : VACANCY_DUE_DATE_CONSTANT.TWO_PLUS;
    };

    return {
      ...mappedVacancy,
      type: ONETIME_VACANCY_TYPE,
      dueDate: calculateDueDate(mappedVacancy.isAsap, mappedVacancy.targetDate),
      vacancyAlias: mappedVacancy.vacancy,
      description: isExistingVacancy ? null : mappedVacancy.description,
    };
  };

  const partialUpdate = async (alias: string, data: any) => {
    const result = await sendPatchRequest(alias, data);
    await invalidateCache();
    return result;
  };

  const updateVacancyManager = async (vacancyAlias: string, alias: string) => {
    return sendPutRequest(`${vacancyAlias  }/manager`, { managerAlias: alias });
  };

  const create = async (vacancy: any) => {
    const mapped = await mapVacancy(vacancy);
    return baseCreate(mapped);
  };

  const createOneStop = async (hiring: any) => {
    const mapped = await mapHiring(hiring);
    const response = await sendPostRequest('/vacancies/request', mapped);
    return response.json();
  };

  const update = async (alias: StringOrNumber, vacancy: FormFieldsData) => {
    const mapped = await mapVacancy(vacancy);
    return baseUpdate(alias, mapped);
  };

  const getInitialData = async (isOneStop: boolean) => {
    const data: VacancyType = {};
    const commonVacancyDetails: VacancyDetailsType = {
      assignmentType: Object.keys(EMPLOYEE_CONTRACT_ASSIGMENT_TYPES).filter(key => key === 'FULL_TIME'),
      contractType: Object.keys(VACANCY_EMPLOYEE_CONTRACT_TYPES_NO_INTERNSHIP).filter(key =>
        ['EC', 'B2B'].includes(key),
      ),
      relocationOption: 'OPTIONAL',
      hasProbation: true,
      workplaceLocation: Object.keys(EMPLOYEE_WORKPLACE_LOCATION),
      workingSchedule: 'STANDARD',
    };
    const offices = await getOfficeOptions();

    data.vacancyDetails = [
      {
        office: getOfficeOption(offices, 'Krakow'),
        ...commonVacancyDetails,
      },
      {
        office: getOfficeOption(offices, 'Minsk'),
        ...commonVacancyDetails,
      },
    ];

    if (isOneStop) {
      return {
        ...data,
        expirationDate: formatDateForAPI(defaultExpirationDate),
      };
    }

    return {
      ...data,
    };
  };

  const getById = async (id: string) => {
    const data = await baseGetById(id);

    return {
      ...data,
      competencyLevel: data.competencyLevel === null ? COMPETENCY_LEVEL['N/A'] : data.competencyLevel,
    };
  };

  const searchByNameAndId = async (query: string) => {
    const data = await baseCrudRequests.search({
      pageNumber: 0,
      pageSize: 30,
      filter: { 'specification:search': { eq: query } },
    });

    return data.result;
  };

  const getValidationSchema = () => Promise.resolve(vacancyValidation);

  const oneStopValidationSchema = useHiringValidationOneStop();
  const getValidationSchemaOneStop = () => Promise.resolve(oneStopValidationSchema);

  return {
    getValidationSchema,
    getValidationSchemaOneStop,
    createOneStop,
    getOfficeOptions,
    getPositionOptions,
    partialUpdate,
    updateVacancyManager,
    ...baseCrudRequests,
    create,
    update,
    getInitialData,
    searchByNameAndId,
    getById,
  };
};

export default useVacancyService;
