/* istanbul ignore file */
// libs
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
// components
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowAccountPageMenu from 'crm/account/Show/ShowAccountPageMenu';
import SingleAccountBreadcrumbs from 'crm/account/shared/SingleAccountBreadcrumbs';
// services
import useAccountService from 'crm/account/accountService';
import AccountEntityHeader from 'crm/account/shared/AccountEntityHeader';

type ViewAccountProps = {
  children: ReactElement | ReactElement[];
  breadcrumbs?: ReactElement;
  hasEntityHeader?: boolean;
};

const ViewAccount = ({
  children,
  breadcrumbs = <SingleAccountBreadcrumbs />,
  hasEntityHeader = true,
}: ViewAccountProps) => {
  const { id } = useParams<{ id: string }>();
  const { getById } = useAccountService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        menu={<ShowAccountPageMenu />}
        breadcrumbs={breadcrumbs}
        entityHeader={hasEntityHeader ? <AccountEntityHeader /> : null}
      >
        {children}
      </ShowPageLayout>
    </Show>
  );
};

export default ViewAccount;
