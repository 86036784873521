import React, { useEffect, useState } from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import useInputHelper from './inputHelper';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import { CommonInputLayoutProps, DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import InputLoader from '../../InputLoader';
import { SelectComponentsConfig } from 'react-select';

const DEFAULT_PLACEHOLDER = 'Select...';

type Suggestion = {
  id: string | number;
  name: string;
};

export interface LoadMultiSelectInputLayoutProps extends CommonInputLayoutProps<object, Nullable<Option[]>> {
  placeholder?: string;
  noOptionsMessage?: (value?: Dictionary<string>) => Nullable<string>;
  options: any[];
  menuPortalTarget?: Nullable<HTMLElement>;
  components?: SelectComponentsConfig<any, any, any>;
}

export type LoadMultiSelectInputLayoutType = ReactComponent<LoadMultiSelectInputLayoutProps>;

export interface LoadMultiSelectInputProps extends DefaultInputPropTypes<Option[]> {
  loadSuggestionsMethod: () => ResultResourceData;
  mapResults?: (data: Suggestion[]) => Option[];
  debounceDelay?: number;
  noOptionsMessage?: (value?: Dictionary<string>) => string;
  defaultOptions?: any;
  menuPortalTarget?: Nullable<HTMLElement>;
  deps?: any[];
  components?: SelectComponentsConfig<any, any, any>;
}

const LoadMultiSelectInput = ({
  loadSuggestionsMethod,
  mapResults,
  debounceDelay,
  noOptionsMessage = () => 'Nothing was found',
  defaultOptions,
  deps = [],
  ...props
}: LoadMultiSelectInputProps) => {
  const { LoadMultiSelectInputLayout } = useUiTheme<LoadMultiSelectInputLayoutType>();
  const inputHelper = useInputHelper(props);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const result = await loadSuggestionsMethod();
        const mappedOptions = mapResults ? mapResults(result) : result;
        setOptions(mappedOptions || []);
      } catch (error) {
        throw new Error(error as string);
      } finally {
        setLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    const currentValue = inputHelper.getValue() || [];
    if (!!options.length && currentValue.some((item: { value: any }) => !item.value)) {
      inputHelper.initializeValue(
        currentValue.map((item: any) => {
          return options.filter((option: any) => option.value === item)[0];
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  if (loading) {
    return <InputLoader />;
  } else {
    return (
      <LoadMultiSelectInputLayout
        noOptionsMessage={noOptionsMessage}
        isVisible={inputHelper.isVisible()}
        label={inputHelper.getLabel()}
        source={inputHelper.getSource()}
        onChangeCallback={inputHelper.getOnChangeCallback()}
        onBlurCallback={inputHelper.getValidationCallback()}
        onFocusCallback={inputHelper.getClearValidationCallback()}
        value={inputHelper.getValue()}
        errorMessages={inputHelper.getErrorMessages()}
        isRequired={inputHelper.getIsRequired()}
        placeholder={inputHelper.getPlaceholder() || DEFAULT_PLACEHOLDER}
        options={options}
        menuPortalTarget={props.menuPortalTarget}
        components={props?.components || {}}
      />
    );
  }
};

export default LoadMultiSelectInput;
