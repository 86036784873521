import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HierarchyPointNode } from './TreeNode';
import useStorageService from 'shared/uibuilder/storageService';
import { CustomNodeElementProps } from 'react-d3-tree';
import { flushSync } from 'react-dom';

const CACHE_NAME = 'tree-data';

const DEFAULT_TRANSLATE_DATA = {
  x: 300,
  y: Math.max((window.innerHeight - 200) / 2, 220),
};

const useTreeHelper = ({ defaultZoom }: { defaultZoom: number }) => {
  const treeContainer = useRef<any>(null);
  const treeRef = useRef<any>(null);
  const [treeDimensions, setTreeDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [activeNodeId, setActiveNodeId] = useState<Nullable<string>>(null);
  const [collapsedNodes, setCollapsedNodes] = useState<Nullable<string[]>>(null);
  const [translateData, setTranslateData] = useState(DEFAULT_TRANSLATE_DATA);
  const [zoom, setZoom] = useState<number>(defaultZoom);
  const { pathname } = useLocation();

  const getCacheId = () => `${pathname}-${CACHE_NAME}`;

  const { getDataFromStorage, setDataToStorage } = useStorageService(
    getCacheId(),
    () => ({
      collapsedNodes: [],
    }),
    '1.0',
  );

  const saveTreeStateToStorage = () => {
    if (collapsedNodes) {
      setDataToStorage({
        collapsedNodes,
      });
    }
  };

  useEffect(() => {
    saveTreeStateToStorage();
    // Suppressed warnings because we only need to call useEffect callback if collapsedNodes is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedNodes]);

  const updateCollapsedNodesData = (nodeId: string) => {
    if (collapsedNodes?.includes(nodeId)) {
      setCollapsedNodes(prevState => (prevState || []).filter(id => id !== nodeId));
    } else {
      setCollapsedNodes(prevState => [...(prevState || []), nodeId]);
    }
  };

  const getTransformDataFromStyle = () => {
    const graph = treeContainer?.current?.querySelector('.rd3t-g');

    if (!graph) {
      return {
        scale: zoom,
        translate: translateData,
      };
    }

    const getStyleValue = (value: string) => value.replace(/[()]/g, '');

    const transform = graph.getAttribute('transform').match(/\((.*?)\)/g);
    const scale = +getStyleValue(transform[1]);
    const translate = getStyleValue(transform[0]).split(',');

    return {
      scale,
      translate: {
        x: +translate[0],
        y: +translate[1],
      },
    };
  };

  const getTransformData = (hierarchyPointNode: HierarchyPointNode) => {
    const { scale } = getTransformDataFromStyle();

    if (scale) {
      const x = -hierarchyPointNode.x * scale + treeDimensions.width / 2;
      const y = -hierarchyPointNode.y * scale + treeDimensions.height / 2;

      return {
        scale,
        translate: {
          x,
          y,
        },
      };
    }

    return {};
  };

  const onChangeNode = (hierarchyPointNode: HierarchyPointNode) => {
    if (!activeNodeId || activeNodeId !== hierarchyPointNode.data.id) {
      return;
    }

    const { translate } = getTransformData(hierarchyPointNode);

    // Added `flushSync`s to fix bug with tree centering
    flushSync(() => setActiveNodeId(null));

    if (translate) {
      flushSync(() =>
        setTranslateData({
          x: translate.x + Math.random() * 0.00001, // add a small value to force node center position after update
          y: translate.y,
        }),
      );
    }
  };

  const onToggleNode = (hierarchyPointNode: HierarchyPointNode) => {
    const { scale, translate } = getTransformData(hierarchyPointNode);
    const { id } = hierarchyPointNode.data;

    if (!id) {
      return;
    }

    setActiveNodeId(id);
    updateCollapsedNodesData(id);

    if (scale && translate) {
      setZoom(scale);
    }
  };

  const initInitialSettings = async () => {
    const container = treeContainer?.current;
    const dimensions = container?.getBoundingClientRect();
    const graphHeight = container?.querySelector('.rd3t-g')?.getBoundingClientRect()?.height || 0;
    const initialData = await getDataFromStorage();

    setCollapsedNodes(initialData.collapsedNodes);
    setZoom(defaultZoom);

    if (dimensions) {
      setTranslateData({
        x: dimensions.width / 2,
        y: Math.max((dimensions.height - graphHeight) / 2, 150),
      });

      setTreeDimensions({
        width: dimensions.width,
        height: dimensions.height,
      });
    }
  };

  const checkNodeState = (rd3tNodeProps: CustomNodeElementProps) => {
    const nodeId = (rd3tNodeProps.nodeDatum as any).id;
    const shouldBeCollapsed = collapsedNodes?.includes(nodeId);
    // eslint-disable-next-line no-underscore-dangle
    const { collapsed } = rd3tNodeProps.nodeDatum.__rd3t;

    if (shouldBeCollapsed && !collapsed) {
      rd3tNodeProps.toggleNode();
    }

    if (!shouldBeCollapsed && collapsed) {
      updateCollapsedNodesData(nodeId);
    }
  };

  const updateTreeState = () => {
    const tree = treeRef.current;
    if (!tree || !collapsedNodes) {
      return;
    }

    const updateNodeState = (nodes: any[]): any[] => {
      return nodes.map((item: any) => {
        return {
          ...item,
          children: item.children ? updateNodeState(item.children) : null,
          // eslint-disable-next-line no-underscore-dangle
          __rd3t: {
            // eslint-disable-next-line no-underscore-dangle
            ...item.__rd3t,
            collapsed: collapsedNodes.includes(item.id),
          },
        };
      });
    };

    const data = updateNodeState(tree.state.data);

    tree.setState({ data });
  };

  return {
    collapsedNodes,
    zoom,
    translateData,
    initInitialSettings,
    treeContainer,
    onToggleNode,
    onChangeNode,
    updateCollapsedNodesData,
    setActiveNodeId,
    checkNodeState,
    treeRef,
    updateTreeState,
  };
};

export default useTreeHelper;
