/* istanbul ignore file */
import React from 'react';
import { useCandidateRoute, useCandidateUrl } from 'erp/candidate/CandidateRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Switch, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { Route } from 'shared/routing';
import { READ, UPDATE } from 'crm/crmService';
import Page404 from 'shared/uibuilder/Page404';
import { CREATE_CANDIDATE_OFFER, READ_LIST_CANDIDATE_OFFER } from 'erp/candidate/shared/candidateService';
import CandidateOfferContext from 'erp/candidate/offercompensation/CandidateOfferContext';
import CandidateOfferList from 'erp/candidate/offercompensation/List';
import { Create, Update } from 'erp/candidate/offercompensation/createupdate';
import ViewCandidateOffer from 'erp/candidate/offercompensation/Show';

export const CANDIDATE_OFFER_ID = 'candidateOfferId';
export const PATH = '/offers';

export const useCandidateOfferRoute = () => {
  const { singleEntityRoute: candidateRoute } = useCandidateRoute();

  return useRoute({
    listRoute: `${candidateRoute}${PATH}`,
    singleEntityRoute: `${candidateRoute}${PATH}/:${CANDIDATE_OFFER_ID}`,
  });
};

export const useCandidateOfferUrl = () => {
  const { getSingleEntityUrl } = useCandidateUrl();
  const candidateLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${candidateLink}${PATH}`,
    idSource: CANDIDATE_OFFER_ID,
  });
};

export const useCandidateOfferId = () => {
  const params: Dictionary<string> = useParams<Dictionary<string>>();
  return params.candidateOfferId;
};

const CandidateOfferRouter = () => {
  const { listRoute, createRoute, updateRoute, singleEntityRoute } = useCandidateOfferRoute();

  return (
    <Switch>
      <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_LIST_CANDIDATE_OFFER}>
        <CandidateOfferList />
      </GlobalProtectedRoute>

      <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_CANDIDATE_OFFER}>
        <Create />
      </GlobalProtectedRoute>

      <Route path={updateRoute} exact>
        <CandidateOfferContext permissionToCheck={UPDATE}>
          <Update />
        </CandidateOfferContext>
      </Route>

      <Route path={singleEntityRoute} exact>
        <CandidateOfferContext permissionToCheck={READ}>
          <ViewCandidateOffer />
        </CandidateOfferContext>
      </Route>

      <Route component={Page404} />
    </Switch>
  );
};

export default CandidateOfferRouter;
