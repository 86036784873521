import useFormValidation from 'shared/uibuilder/form/useFormValidation';
import { getBytesFromMb } from 'artifact/artifactService';
import { getHumanReadableDataSize } from 'shared/fileHelper';

const getExtension = (filename: string) => {
  const extension = filename.split('.').pop();

  return extension && extension.toLowerCase();
};

const useArtifactValidationService = ({
  source,
  previewsCount = 0,
  fileTypes,
  maxMb,
}: {
  source?: string;
  previewsCount?: number;
  fileTypes?: string;
  maxMb?: number;
}) => {
  const { getMaxElements } = useFormValidation(source && source);

  const validate = (files: File[]) => {
    let error = null;

    if (files.length > getMaxElements() - (previewsCount && previewsCount)) {
      error = `Maximum number of files is ${getMaxElements()}`;
    }

    [].forEach.call(files, (file: File) => {
      const fileExtension: any = getExtension(file.name);

      if (fileTypes) {
        const allowedFileTypes = fileTypes.split(',');
        if (!allowedFileTypes.includes(fileExtension)) {
          error = 'Invalid file extension';
        }
      }

      if (maxMb && file.size > getBytesFromMb(maxMb)) {
        error = `File size exceeds ${getHumanReadableDataSize(getBytesFromMb(maxMb))}`;
      }
    });

    return error;
  };

  return {
    validate,
  };
};

export default useArtifactValidationService;
