import React from 'react';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Switch, useParams } from 'react-router-dom';
import { ProtectedRouter } from 'routing';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_TRAINING_DOMAIN, WRITE_TRAINING_DOMAIN } from '../useTrainingsService';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import TrainingInstancesList from './List/TrainingInstancesList';
import CreateTrainingInstance from './createupdate/create';
import ViewTrainingInstancesPage from './show';
import UpdateTrainingInstance from './createupdate/update';

export const TRAININGS_INSTANCES_PATH = '/trainings-instances';

export const useTrainingInstancesRoute = () => {
  const routes = useRoute({ listRoute: TRAININGS_INSTANCES_PATH });

  return {
    ...routes,
  };
};

export const useTrainingInstancesUrl = () => {
  const entityUrl = useEntityUrl({
    baseLink: TRAININGS_INSTANCES_PATH,
  });

  return {
    ...entityUrl,
  };
};

export const useTrainingInstancesId = () => {
  const params: Dictionary<string> = useParams<Dictionary<string>>();

  return params.id;
};

const TrainingInstancesRouter = () => {
  const { listRoute, createRoute, singleEntityRoute, updateRoute } = useTrainingInstancesRoute();

  return (
    <ProtectedRouter basePath={TRAININGS_INSTANCES_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={[READ_TRAINING_DOMAIN]}>
          <TrainingInstancesList />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={[WRITE_TRAINING_DOMAIN]}>
          <CreateTrainingInstance />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={updateRoute} exact globalPermissionToCheck={[WRITE_TRAINING_DOMAIN]}>
          <UpdateTrainingInstance />
        </GlobalProtectedRoute>

        <GlobalProtectedRoute path={singleEntityRoute} exact globalPermissionToCheck={[READ_TRAINING_DOMAIN]}>
          <ViewTrainingInstancesPage />
        </GlobalProtectedRoute>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

TrainingInstancesRouter.getRouterPath = () => TRAININGS_INSTANCES_PATH;

export default TrainingInstancesRouter;
