/* istanbul ignore file */
import React from 'react';
import { CRM_PATH } from 'crm/crmService';
import { READ_SECURITY_AUDIT_LOG } from 'audit/shared/auditService';
import { ARTIFACTS_AUDIT_PATH, PERMISSIONS_AUDIT_PATH, ROLES_AUDIT_PATH } from 'audit/AuditRouter';
import { ONE_CLICK_PATH } from 'authentication/AuthenticationRouter';
import isOneClickEnabled from 'authentication/OneClick/isOneClickEnabled';
import { ONE_STOP_PATH } from 'oneStop/OneStopRouterHelper';
import { ONE_STOP_HISTORY_PATH, SENT_FEEDBACK_HISTORY } from 'myActivity/MyActivityRouterHelper';
import { READ_CAMUNDA_METRICS_REPORT } from 'camunda/metrics/shared/camundaMetricsService';
import { CAMUNDA_PATH } from 'camunda';
import { CAMUNDA_METRICS_PATH } from 'camunda/metrics/CamundaMetricsRouter';
import { LEADS_PATH } from 'crm/lead/LeadRouter';
import { READ_LEADS_LIST } from 'crm/lead/shared/leadService';
import { ACCOUNT_PATH } from 'crm/account/AccountRouter';
import { READ_ACCOUNTS_LIST } from 'crm/account/accountService';
import { CONTACTS_PATH } from 'crm/contact/ContactRouter';
import { READ_CONTACTS_LIST } from 'crm/contact/shared/contactService';
import { OPPORTUNITY_PATH } from 'crm/opportunity/OpportunityRouter';
import { READ_OPPORTUNITIES_LIST } from 'crm/opportunity/shared/opportunityService';
import { EMPLOYEES_PATH, MY_PAGE_PATH } from 'erp/employee/EmployeesRouter';
import { GET_EMPLOYEES_LIST } from 'erp/employee/employeeService';
import { CONTRACTS_PATH } from 'erp/contracts/ContractsRouter';
import { GET_ALL_CONTRACTS_LIST } from 'erp/contracts/shared/contractsService';
import { CANDIDATES_PATH } from 'erp/candidate/CandidateRouter';
import { READ_CANDIDATES_LIST } from 'erp/candidate/shared/candidateService';
import { LEAVE_PATH, READ_LEAVES_LIST } from 'erp/leave/shared/leaveService';
import { READ_PAYROLL_REPORT } from 'erp/payroll/shared/payrollService';
import { PAYROLL_REPORT_PATH } from 'erp/payroll/PayrollRouter';
import { PAYROLL_WORKING_HOURS_PATH } from 'erp/workinghours/WorkingHoursRouter';
import { READ_WORKING_HOURS_REPORT } from 'erp/workinghours/shared/workingHoursService';
import { PROCESS_DEFINITION_PATH } from 'camunda/monitoring/definition';
import { READ_DEFINITIONS_LIST } from 'camunda/monitoring/definition/processDefinitionService';
import { READ_INSTANCES_LIST } from 'camunda/monitoring/instance/processInstanceService';
import { PROCESS_INSTANCE_PATH } from 'camunda/monitoring/instance';
import {
  READ_NEW_COMPETENCY_LIST,
  READ_NEW_COMPETENCY_LIST_URL,
} from 'erp/qualification/newCompetency/newCompetencyService';
import { READ_VACANCIES_LIST, VACANCY_PATH } from 'erp/recruitment/vacancy/shared/vacancyService';
import { PROJECT_PATH, READ_PROJECTS_LIST } from 'erp/project/shared/projectService';
import { ASSIGNMENT_PATH, READ_ASSIGNMENTS_LIST } from 'erp/assignment/shared/assignmentService';
import { PERFORMANCE_REPORTS_PATH, READ_PERFORMANCE_REPORT } from 'erp/performanceReport/usePerformanceReportService';
import { HANDBOOK_PATH } from 'handbook/HandbookRouter';
import { INTERVIEW_PATH, READ_INTERVIEWS_LIST } from 'erp/interview/shared/interviewService';
import { RBS_PATH, READ_RBS_TREE_NODES_LIST } from 'erp/rbs/shared/rbsService';
import { BUDGET_PATH, READ_BUDGET_REPORT, READ_BUDGETS_LIST } from 'erp/budget/shared/budgetService';
import { BUDGET_REPORT_PATH } from 'erp/budget/report/BudgetReportRouter';
import { ARTIFACT_STORAGE_PATH, FE_VIEW_ARTIFACT_STORAGE } from 'artifactStorage/shared/artifactStorageService';
import { READ_SKILL_BASED_QUEUES_LIST } from 'erp/skillbasedqueue/shared/skillBasedQueueService';
import { SKILL_BASED_QUEUE_PATH } from 'erp/skillbasedqueue/SkillBasedQueueRouter';
import {
  READ_VACANCY_PUBLICATION_LIST,
  VACANCY_PUBLICATION_PATH,
} from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import ResponsibilitiesIcon from '@mui/icons-material/AccountTreeOutlined';
import MoodIcon from '@mui/icons-material/Mood';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import OneStopIcon from '@mui/icons-material/InsertDriveFileOutlined';
import BudgetAccountingIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ProjectsIcon from '@mui/icons-material/DashboardOutlined';
import CrmIcon from '@mui/icons-material/ContactPageOutlined';
import AuditIcon from '@mui/icons-material/LanguageOutlined';
import ProcessesIcon from '@mui/icons-material/MergeTypeOutlined';
import ArtifactStorageIcon from '@mui/icons-material/SourceOutlined';
import OfficeManagementIcon from '@mui/icons-material/Business';
import { READ_SYBERRY_TODAY, SYBERRY_TODAY_PATH } from 'syberryToday/useSyberryTodayService';
import { PROJECTS_DASHBOARD_PATH, VIEW_PROJECTS_DASHBOARD } from 'crm/projectsDashboard/useProjectsDashboardService';
import { READ_SERVICES_LIST, SERVICE_PATH } from 'erp/service/shared/serviceService';
import useFeatureToggle, { Features } from 'featuretoggle';
import { COST_ACCOUNTING_PATH } from 'erp/costaccounting/CostAccountingRouter';
import { READ_COST_CENTER_REPORT } from 'erp/costaccounting/shared/costAccountingService';
import { LEDGER_PATH, READ_LEDGER_LIST } from 'financialAnalytic/ledger/useLedgerService';
import { JOURNAL_ENTRY_PATH, READ_JOURNAL_ENTRY } from 'financialAnalytic/journalEntry/useJournalEntryService';
import { INVOICE_PATH, READ_INVOICE_LIST } from 'financialAnalytic/invoice/useInvoiceService';
import { ASSET_PATH, READ_ASSET_LIST } from 'financialAnalytic/assets/useAssetService';
import { EXPENSE_PATH, READ_EXPENSE_LIST } from 'financialAnalytic/expenses/useExpenseService';
import { PURCHASE_ORDER_PATH, READ_PURCHASE_ORDER_LIST } from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import { PAYROLL_REPORTS_PATH, READ_PAYROLL_REPORTS } from 'erp/payroll/generatedPayrolls/useGeneratedPayrollsService';
import {
  PAYROLL_DISTRIBUTION_PATH,
  READ_PAYROLL_DISTRIBUTION,
} from 'erp/payroll/payrollDistribution/usePayrollDistributionService';
import { APPLICATION_PATH, READ_APPLICATION } from 'erp/recruitment/applications/shared/applicationService';
import { READ_RECONCILIATION, RECONCILIATION_PATH } from 'erp/costaccounting/reconcilation/useReconciliationService';
import { READ_WASTE_LIST, WASTE_PATH } from 'erp/costaccounting/waste/useWasteService';
import { ACCOUNTING_CYCLE_PATH, GET_ACCOUNTING_CYCLE } from 'erp/costaccounting/accountingCycle/useAccountingCycle';
import {
  CUSTOMER_INVOICE_PATH,
  READ_CUSTOMER_INVOICE_LIST,
} from '../../financialAnalytic/customerInvoices/useCustomerInvoiceService';
import {
  CORPORATE_EVENT_PATH,
  READ_CORPORATE_EVENT_LIST,
} from 'financialAnalytic/corporateEvents/useCorporateEventService';
import { TRAININGS_PATH } from 'erp/trainings/TrainingsRouter';
import { TRAININGS_INSTANCES_PATH } from 'erp/trainings/trainingInstances/TrainingInstancesRouter';
import { READ_TRAINING_DOMAIN } from 'erp/trainings/useTrainingsService';
import { READ_ROOM_LIST, ROOM_PATH } from 'erp/room/useRoomService';
import { HIRING_PATH, HIRING_REQUEST_READ } from 'erp/recruitment/hiring/useHiringService';
import { OFFICE_PATH } from 'erp/office/useOfficeService';
import { RECRUITING_DASHBOARD_PATH } from 'erp/recruitment/dashboard/RecruitingDashboardRouter';
import { HIRING_REQUEST_DASHBOARD } from 'erp/recruitment/dashboard/useRecruitingDashboardService';

const PEOPLE_PATH = '/people';
const BUSINESS_UNITS_PATH = '/business_units';
const OFFICE_MANAGEMENT_PATH = '/office-management';
const PEOPLE_MANAGEMENT_PATH = '/people-management';

/* eslint-disable react-hooks/rules-of-hooks */
const syberryTodayEnabled = useFeatureToggle().isFeatureEnabled(Features.SYBERRY_TODAY);
const assignmentSidebarEnabled = useFeatureToggle().isFeatureEnabled(Features.ASSIGNMENT_SIDEBAR);
const budgetSidebarEnabled = useFeatureToggle().isFeatureEnabled(Features.BUDGET_SIDEBAR);
const crmProjectDashboardEnabled = useFeatureToggle().isFeatureEnabled(Features.CRM_PROJECTS_DASHBOARD);
const ledgerEnabled = useFeatureToggle().isFeatureEnabled(Features.LEDGER);
const invoicesEnabled = useFeatureToggle().isFeatureEnabled(Features.INVOICES);
const payrollsEnabled = useFeatureToggle().isFeatureEnabled(Features.PAYROLL);
const customerInvoicesEnabled = useFeatureToggle().isFeatureEnabled(Features.CUSTOMER_INVOICES);
const tradingsEnabled = useFeatureToggle().isFeatureEnabled(Features.TRAININGS);
const officesEnabled = useFeatureToggle().isFeatureEnabled(Features.OFFICES);
const corporateEventsEnabled = useFeatureToggle().isFeatureEnabled(Features.CORPORATE_EVENTS);
/* eslint-disable react-hooks/rules-of-hooks */

const oneClick = isOneClickEnabled()
  ? [
      {
        name: 'One Click Sign In',
        url: ONE_CLICK_PATH,
        permissions: null,
      },
    ]
  : [];

const oneStop = [
  {
    name: 'Syberry Today',
    url: syberryTodayEnabled ? SYBERRY_TODAY_PATH : ONE_STOP_PATH,
    icon: <OneStopIcon />,
    permissions: null,
    children: [
      ...(syberryTodayEnabled
        ? [
            {
              name: 'My day',
              url: SYBERRY_TODAY_PATH,
              permissions: [READ_SYBERRY_TODAY],
              isPageMenu: true,
            },
          ]
        : []),
      {
        name: 'One Stop',
        url: ONE_STOP_PATH,
        permissions: null,
        isPageMenu: true,
      },
      {
        name: 'One Stop history',
        url: ONE_STOP_HISTORY_PATH,
        permissions: null,
        isPageMenu: true,
      },
      {
        name: 'Feedback I left',
        url: SENT_FEEDBACK_HISTORY,
        permissions: null,
        isPageMenu: true,
      },
      {
        name: 'My Page',
        isExact: true,
        url: MY_PAGE_PATH,
        permissions: null,
        isPageMenu: true,
      },
      {
        name: 'Handbook',
        url: HANDBOOK_PATH,
        isExact: true,
        permissions: null,
        isPageMenu: true,
      },
      {
        name: 'Qualification Model',
        url: READ_NEW_COMPETENCY_LIST_URL,
        isExact: true,
        permissions: [READ_NEW_COMPETENCY_LIST],
        isPageMenu: true,
      },
    ],
  },
];

const people = [
  {
    name: 'People',
    url: PEOPLE_PATH,
    icon: <PeopleIcon />,
    permissions: [
      READ_CANDIDATES_LIST,
      READ_INTERVIEWS_LIST,
      READ_VACANCIES_LIST,
      GET_EMPLOYEES_LIST,
      GET_ALL_CONTRACTS_LIST,
      READ_LEAVES_LIST,
      READ_ASSIGNMENTS_LIST,
      READ_PERFORMANCE_REPORT,
      HIRING_REQUEST_READ,
      HIRING_REQUEST_DASHBOARD,
    ],
    children: [
      {
        name: 'Recruitment',
        shouldUseCurrentName: true,
        url: RECRUITING_DASHBOARD_PATH,
        permissions: [
          READ_CANDIDATES_LIST,
          HIRING_REQUEST_READ,
          READ_INTERVIEWS_LIST,
          READ_VACANCIES_LIST,
          READ_APPLICATION,
          HIRING_REQUEST_DASHBOARD,
        ],
        children: [
          {
            name: 'Dashboard',
            url: RECRUITING_DASHBOARD_PATH,
            permissions: [HIRING_REQUEST_DASHBOARD],
            isPageMenu: true,
          },
          {
            name: 'Candidates',
            url: CANDIDATES_PATH,
            permissions: [READ_CANDIDATES_LIST],
            isPageMenu: true,
          },
          {
            name: 'Interviews',
            url: INTERVIEW_PATH,
            exact: true,
            permissions: [READ_INTERVIEWS_LIST],
            isPageMenu: true,
          },
          {
            name: 'Vacancies',
            url: VACANCY_PATH,
            permissions: [READ_VACANCIES_LIST],
            isPageMenu: true,
          },
          {
            name: 'Hiring requests',
            url: HIRING_PATH,
            permissions: [HIRING_REQUEST_READ],
            isPageMenu: true,
          },
          {
            name: 'Vacancy Publications',
            url: VACANCY_PUBLICATION_PATH,
            permissions: [READ_VACANCY_PUBLICATION_LIST],
            isPageMenu: true,
          },
          {
            name: 'Applications',
            url: APPLICATION_PATH,
            permissions: [READ_APPLICATION],
            isPageMenu: true,
          },
        ],
      },
      tradingsEnabled && {
        name: 'Trainings',
        url: TRAININGS_PATH,
        permissions: [READ_TRAINING_DOMAIN],
        children: [
          {
            name: 'Trainings',
            url: TRAININGS_PATH,
            permissions: [READ_TRAINING_DOMAIN],
            isPageMenu: true,
            exact: true,
          },
          {
            name: 'Instances',
            url: TRAININGS_INSTANCES_PATH,
            permissions: [READ_TRAINING_DOMAIN],
            isPageMenu: true,
            exact: true,
          },
        ],
      },
      {
        name: 'Employees',
        exceptionUrls: [MY_PAGE_PATH],
        url: EMPLOYEES_PATH,
        permissions: [GET_EMPLOYEES_LIST, GET_ALL_CONTRACTS_LIST, READ_LEAVES_LIST],
        children: [
          {
            name: 'Employees',
            exceptionUrls: [MY_PAGE_PATH],
            url: EMPLOYEES_PATH,
            permissions: [GET_EMPLOYEES_LIST],
            isPageMenu: true,
          },
          {
            name: 'Contracts',
            url: CONTRACTS_PATH,
            permissions: [GET_ALL_CONTRACTS_LIST],
            isPageMenu: true,
          },
          {
            name: 'Leaves',
            url: LEAVE_PATH,
            permissions: [READ_LEAVES_LIST],
            isPageMenu: true,
          },
        ],
      },
      assignmentSidebarEnabled && {
        name: 'Resource Management',
        url: ASSIGNMENT_PATH,
        permissions: [READ_ASSIGNMENTS_LIST],
        children: [
          {
            name: 'Assignments',
            url: ASSIGNMENT_PATH,
            permissions: [READ_ASSIGNMENTS_LIST],
            isPageMenu: true,
          },
        ],
      },
      {
        name: 'Performance report',
        url: PERFORMANCE_REPORTS_PATH,
        permissions: [READ_PERFORMANCE_REPORT],
      },
    ].filter(Boolean),
  },
];

const crm = [
  {
    name: 'Sales',
    url: CRM_PATH,
    icon: <CrmIcon />,
    permissions: [
      READ_LEADS_LIST,
      READ_ACCOUNTS_LIST,
      ...(crmProjectDashboardEnabled ? [VIEW_PROJECTS_DASHBOARD] : []),
    ],
    children: [
      {
        name: 'Leads',
        url: LEADS_PATH,
        permissions: [READ_LEADS_LIST],
      },
      {
        name: 'Opportunities',
        url: OPPORTUNITY_PATH,
        permissions: [READ_OPPORTUNITIES_LIST],
      },
      {
        name: 'Accounts',
        url: ACCOUNT_PATH,
        permissions: [READ_ACCOUNTS_LIST],
      },
      {
        name: 'Contacts',
        url: CONTACTS_PATH,
        permissions: [READ_CONTACTS_LIST],
      },
      ...(crmProjectDashboardEnabled
        ? [
            {
              name: 'Projects dashboard',
              url: PROJECTS_DASHBOARD_PATH,
              permissions: [VIEW_PROJECTS_DASHBOARD],
            },
          ]
        : []),
    ],
  },
];

const camunda = [
  {
    name: 'Processes',
    url: CAMUNDA_PATH,
    icon: <ProcessesIcon />,
    permissions: [READ_CAMUNDA_METRICS_REPORT, READ_DEFINITIONS_LIST, READ_INSTANCES_LIST],
    children: [
      {
        name: 'Reports',
        url: CAMUNDA_METRICS_PATH,
        exact: true,
        permissions: [READ_CAMUNDA_METRICS_REPORT],
      },
      {
        name: 'Process definitions',
        url: PROCESS_DEFINITION_PATH,
        permissions: [READ_DEFINITIONS_LIST],
      },
      {
        name: 'Process instances',
        url: PROCESS_INSTANCE_PATH,
        permissions: [READ_INSTANCES_LIST],
      },
    ],
  },
];

const securityAuditArtifacts = [
  {
    name: 'Artifacts',
    url: ARTIFACTS_AUDIT_PATH,
    permissions: [READ_SECURITY_AUDIT_LOG],
    isPageMenu: true,
  },
];

const securityAudit = [
  {
    name: 'Security',
    url: ROLES_AUDIT_PATH,
    icon: <AuditIcon />,
    permissions: [READ_SECURITY_AUDIT_LOG],
    children: [
      {
        name: 'Roles',
        url: ROLES_AUDIT_PATH,
        permissions: [READ_SECURITY_AUDIT_LOG],
        isPageMenu: true,
      },
      {
        name: 'Permissions',
        url: PERMISSIONS_AUDIT_PATH,
        permissions: [READ_SECURITY_AUDIT_LOG],
        isPageMenu: true,
      },
      ...securityAuditArtifacts,
    ],
  },
];

const budgetAccounting = [
  {
    name: 'Finance',
    url: PEOPLE_PATH,
    icon: <BudgetAccountingIcon />,
    permissions: [
      READ_BUDGETS_LIST,
      READ_SKILL_BASED_QUEUES_LIST,
      READ_COST_CENTER_REPORT,
      READ_LEDGER_LIST,
      READ_JOURNAL_ENTRY,
      READ_INVOICE_LIST,
      READ_CUSTOMER_INVOICE_LIST,
      READ_ASSET_LIST,
      READ_EXPENSE_LIST,
    ],
    children: [
      budgetSidebarEnabled && {
        name: 'Budgets',
        url: BUDGET_PATH,
        permissions: [READ_BUDGETS_LIST, READ_BUDGET_REPORT, READ_SKILL_BASED_QUEUES_LIST],
        children: [
          { name: 'Budgets', url: BUDGET_PATH, permissions: [READ_BUDGETS_LIST], isPageMenu: true },
          {
            name: 'Report',
            url: BUDGET_REPORT_PATH,
            permissions: [READ_BUDGET_REPORT],
            isPageMenu: true,
          },
          {
            name: 'Skill based queues',
            url: SKILL_BASED_QUEUE_PATH,
            permissions: [READ_SKILL_BASED_QUEUES_LIST],
            isPageMenu: true,
          },
        ],
      },
      {
        name: 'Payroll',
        url: PAYROLL_REPORT_PATH,
        permissions: [READ_PAYROLL_REPORT, READ_WORKING_HOURS_REPORT, READ_PAYROLL_REPORTS, READ_PAYROLL_DISTRIBUTION],
        children: [
          {
            name: 'Report',
            url: PAYROLL_REPORT_PATH,
            exact: true,
            permissions: [READ_PAYROLL_REPORT],
            isPageMenu: true,
          },
          payrollsEnabled
            ? {
                name: 'Payroll Reports',
                url: PAYROLL_REPORTS_PATH,
                exact: true,
                permissions: [READ_PAYROLL_REPORTS],
                isPageMenu: true,
              }
            : null,
          {
            name: 'Payroll Distribution',
            url: PAYROLL_DISTRIBUTION_PATH,
            permissions: [READ_PAYROLL_DISTRIBUTION],
            isPageMenu: true,
            exact: true,
          },
          {
            name: 'Working hours',
            url: PAYROLL_WORKING_HOURS_PATH,
            permissions: [READ_WORKING_HOURS_REPORT],
            isPageMenu: true,
          },
        ].filter(Boolean),
      },
      {
        name: 'Cost Accounting',
        shouldUseCurrentName: true,
        url: COST_ACCOUNTING_PATH,
        permissions: [READ_COST_CENTER_REPORT, READ_RECONCILIATION, READ_WASTE_LIST, GET_ACCOUNTING_CYCLE],
        children: [
          {
            name: 'Metrics',
            url: COST_ACCOUNTING_PATH,
            permissions: [READ_COST_CENTER_REPORT],
            isPageMenu: true,
            exact: true,
          },
          {
            name: 'Reconciliation',
            url: RECONCILIATION_PATH,
            permissions: [READ_RECONCILIATION],
            isPageMenu: true,
            exact: true,
          },
          {
            name: 'Waste',
            url: WASTE_PATH,
            permissions: [READ_WASTE_LIST],
            isPageMenu: true,
          },
          {
            name: 'Accounting Cycles',
            url: ACCOUNTING_CYCLE_PATH,
            permissions: [GET_ACCOUNTING_CYCLE],
            isPageMenu: true,
          },
        ],
      },
      ledgerEnabled && {
        name: 'Ledger',
        url: LEDGER_PATH,
        permissions: [READ_LEDGER_LIST],
      },
      ledgerEnabled && {
        name: 'Journal Entries',
        url: JOURNAL_ENTRY_PATH,
        permissions: [READ_JOURNAL_ENTRY],
      },
      invoicesEnabled && {
        name: 'Invoices',
        url: INVOICE_PATH,
        permissions: [READ_INVOICE_LIST],
      },
      customerInvoicesEnabled && {
        name: 'Customer Invoices',
        url: CUSTOMER_INVOICE_PATH,
        permissions: [READ_CUSTOMER_INVOICE_LIST],
      },
      {
        name: 'Assets',
        url: ASSET_PATH,
        permissions: [READ_ASSET_LIST],
      },
      {
        name: 'Employee Expenses',
        url: EXPENSE_PATH,
        permissions: [READ_EXPENSE_LIST],
      },
      {
        name: 'Purchase Orders',
        url: PURCHASE_ORDER_PATH,
        permissions: [READ_PURCHASE_ORDER_LIST],
      },
    ].filter(Boolean),
  },
];

const officeManagement = [
  {
    name: 'Office Management',
    url: OFFICE_MANAGEMENT_PATH,
    icon: <OfficeManagementIcon />,
    permissions: [READ_ROOM_LIST],
    children: [
      officesEnabled && {
        name: 'Offices',
        url: OFFICE_PATH,
        permissions: [READ_ROOM_LIST],
      },
      {
        name: 'Rooms',
        url: ROOM_PATH,
        permissions: [READ_ROOM_LIST],
      },
    ].filter(Boolean),
  },
];

const projectManagement = [
  {
    name: 'Business Units',
    url: BUSINESS_UNITS_PATH,
    icon: <ProjectsIcon />,
    permissions: [READ_PROJECTS_LIST, READ_SERVICES_LIST],
    children: [
      {
        name: 'Projects',
        url: PROJECT_PATH,
        permissions: [READ_PROJECTS_LIST],
      },
      {
        name: 'Services',
        url: SERVICE_PATH,
        permissions: [READ_SERVICES_LIST],
      },
    ],
  },
];

const rbsTree = [
  {
    name: 'Responsibilities',
    url: RBS_PATH,
    icon: <ResponsibilitiesIcon />,
    permissions: [READ_RBS_TREE_NODES_LIST],
  },
];

const artifactStorage = [
  {
    name: 'Artifact Storage',
    url: ARTIFACT_STORAGE_PATH,
    icon: <ArtifactStorageIcon />,
    permissions: [FE_VIEW_ARTIFACT_STORAGE],
  },
];

const peopleManagement = [
  {
    name: 'People Management',
    url: PEOPLE_MANAGEMENT_PATH,
    icon: <MoodIcon />,
    permissions: [READ_CORPORATE_EVENT_LIST],
    children: [
      corporateEventsEnabled && {
        name: 'Corporate Events',
        url: CORPORATE_EVENT_PATH,
        permissions: [READ_CORPORATE_EVENT_LIST],
      },
    ].filter(Boolean),
  },
];

export default {
  items: [
    ...oneStop,
    ...rbsTree,
    ...artifactStorage,
    ...people,
    ...officeManagement,
    ...peopleManagement,
    ...projectManagement,
    ...budgetAccounting,
    ...crm,
    ...securityAudit,
    ...camunda,
    ...oneClick,
  ],
};
