import React from 'react';
import Grid from 'uibuilder/Grid';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { getVisibility } from 'shared/uibuilder/helper';

export interface SectionRowProps {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  colMd?: number;
  colXl?: number;
  colXs?: number;
}

const SectionRow = ({ children, colXs = 12, colMd = 4, colXl = 3 }: SectionRowProps) => {
  const { data } = useShowContext();

  return (
    <Grid container spacing={{ xs: 2, md: 4 }} sx={{ marginTop: '0 !important' }}>
      {React.Children.map(children, (child: any) => {
        return child && getVisibility(data, child && child.props && child.props.isVisible) ? (
          <Grid className="section-item" item xs={colXs} sm={colMd} xl={colXl}>
            {child}
          </Grid>
        ) : null;
      })}
    </Grid>
  );
};

export default SectionRow;
