import { useSearchParameter } from 'shared/routing/helper';
import { useHistory } from 'react-router-dom';

export const PARAMS_KEY = 'candidateId';

const useConversionRouteHelper = () => {
  const { push } = useHistory();
  const candidateId = useSearchParameter(PARAMS_KEY);

  return {
    candidateId,
    setCandidateId: (parameter: any) => {
      if (parameter) {
        push({
          search: `?${PARAMS_KEY}=${parameter}`,
        });
      } else {
        push({
          search: '',
        });
      }
    },
  };
};

export default useConversionRouteHelper;
