/* istanbul ignore file */
import React from 'react';
import { Route } from 'react-router';
import { Switch, useParams } from 'react-router-dom';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { CREATE_EMPLOYEE_OFFER, READ_LIST_EMPLOYEE_OFFER } from 'erp/employee/employeeService';
import { READ, UPDATE } from 'crm/crmService';
import EmployeeOfferContext from 'erp/employee/offers/EmployeeOfferContext';
import UpdateEmployeeOffer from 'erp/employee/offers/createupdate/Update';
import ViewEmployeeOfferPage from 'erp/employee/offers/Show/ViewEmployeeOfferPage';
import EmployeeOfferList from 'erp/employee/offers/List';
import CreateEmployeeOfferPage from 'erp/employee/offers/createupdate/Create';

export const EMPLOYEE_OFFER_ID = 'employeeOfferId';
export const EMPLOYEE_OFFER_PATH = '/offers';

export const useEmployeeOfferRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  return useRoute({
    listRoute: `${employeeRoute}${EMPLOYEE_OFFER_PATH}`,
    singleEntityRoute: `${employeeRoute}${EMPLOYEE_OFFER_PATH}/:${EMPLOYEE_OFFER_ID}`,
  });
};

export const useEmployeeOfferUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${employeeLink}${EMPLOYEE_OFFER_PATH}`,
    idSource: EMPLOYEE_OFFER_ID,
  });
};

export const useEmployeeOfferId = () => {
  const params: Dictionary<string> = useParams<Dictionary<string>>();
  return params.employeeOfferId;
};

const EmployeeOfferRouter = () => {
  const { listRoute, createRoute, updateRoute, singleEntityRoute } = useEmployeeOfferRoute();

  return (
    <Switch>
      <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_EMPLOYEE_OFFER}>
        <CreateEmployeeOfferPage />
      </GlobalProtectedRoute>

      <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_LIST_EMPLOYEE_OFFER}>
        <EmployeeOfferList />
      </GlobalProtectedRoute>

      <Route path={updateRoute} exact>
        <EmployeeOfferContext permissionToCheck={UPDATE}>
          <UpdateEmployeeOffer />
        </EmployeeOfferContext>
      </Route>

      <Route path={singleEntityRoute} exact>
        <EmployeeOfferContext permissionToCheck={READ}>
          <ViewEmployeeOfferPage />
        </EmployeeOfferContext>
      </Route>
    </Switch>
  );
};

export default EmployeeOfferRouter;
