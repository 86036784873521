import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Page403 from './Page403';

import Loading from 'shared/uibuilder/Loading';
import useSecurityReactContext, { SecurityProvider } from './react-context/SecurityReactContext';
import convertArrayToObject from 'shared/authorization/utils';
import { Permission, PermissionsMap } from './authorizationService';
import { ResourceId } from '../crud/baseCrudService';
import { UrlParams } from '../routing/useEntityUrl';

export interface SecurityContextCommonProps {
  children: React.ReactNode;
  id?: ResourceId;
  idSource?: Nullable<string>;
  permissionToCheck?: Nullable<Permission | Permission[]>;
}

interface SecurityContextTemplateProps extends SecurityContextCommonProps {
  contextName: string;
  getPermissionsFunc: (id: string) => Permission[];
}

const defaultIdParam = 'id';

function checkPermission(
  permissionToCheck: Nullable<Permission | Permission[]>,
  contextPermissions: Nullable<PermissionsMap>,
) {
  const permissionsArray = Array.isArray(permissionToCheck) ? permissionToCheck : [permissionToCheck];

  return (
    !permissionToCheck ||
    (contextPermissions && permissionsArray.some(permission => contextPermissions[permission as string]))
  );
}

const SecurityContextTemplate = ({
  children = null,
  contextName,
  id,
  idSource = null,
  getPermissionsFunc,
  permissionToCheck = null,
}: SecurityContextTemplateProps) => {
  const parentContext = useSecurityReactContext() || {};

  const [contextPermissions, setContextPermissions] = useState<Nullable<PermissionsMap>>(null);

  // is HTTP request is sent in current moment
  const [isLoading, setIsLoading] = useState(false);
  // if state is initialized
  const [isInitialized, setIsInitialized] = useState(false);

  const urlParams: UrlParams = useParams<Dictionary<string>>();
  const paramsId = urlParams[idSource || defaultIdParam];

  const entityId = id || paramsId;

  const loadPermissions = useCallback(
    async (idOfEntity: ResourceId) => {
      if (!isInitialized && !isLoading) {
        setIsLoading(true);

        try {
          const permissions = await getPermissionsFunc(idOfEntity as string);
          setContextPermissions(convertArrayToObject(permissions));
          setIsLoading(false);
        } catch (error) {
          if ((error as Response).status === 404) {
            // @ts-ignore
            setContextPermissions([]);
            setIsLoading(false);
          } else {
            throw new Error('Error occurred. Please, reload the page');
          }
        } finally {
          setIsInitialized(true);
        }
      }
    },
    [isLoading, isInitialized, getPermissionsFunc],
  );

  useEffect(() => {
    if (!contextPermissions) {
      loadPermissions(entityId);
    }
  }, [contextPermissions, entityId, loadPermissions]);

  useEffect(() => {
    setIsInitialized(false);
    setContextPermissions(null);
  }, [entityId]);

  const context = {
    ...parentContext,
    currentContext: contextName,
    [contextName]: contextPermissions,
  };

  const hasPermission = checkPermission(permissionToCheck, contextPermissions);

  if (isInitialized && !hasPermission) {
    return <Page403 />;
  }

  if (isLoading || !isInitialized) {
    return <Loading />;
  }

  return <SecurityProvider value={context}>{children}</SecurityProvider>;
};

export default SecurityContextTemplate;
