import { useParams } from 'react-router-dom';
import { BaseRouteConfig } from './useRoute';
import { ResourceId } from '../crud/baseCrudService';

export type UrlParams = Dictionary<string>;

interface EntityUrlHelperParams extends BaseRouteConfig {
  baseLink: string;
  idSource?: string;
}

const useEntityUrl = ({
  baseLink,
  idSource = 'id',
  createPostfix = 'add',
  editPostfix = 'edit',
  timelinePostfix = 'timeline',
}: EntityUrlHelperParams) => {
  const params: UrlParams = useParams<Dictionary<string>>();
  const getSingleEntityUrl = (id?: Nullable<ResourceId>): string => `${baseLink}/${id || params[idSource]}`;

  return {
    getListUrl: () => baseLink,
    getSingleEntityUrl,
    getCreateUrl: () => `${baseLink}/${createPostfix}`,
    getUpdateEntityUrl: (id?: Nullable<ResourceId>) => `${baseLink}/${id || params[idSource]}/${editPostfix}`,
    getTimelineUrl: (id?: Nullable<ResourceId>) => `${getSingleEntityUrl(id)}/${timelinePostfix}`,
  };
};

export default useEntityUrl;
