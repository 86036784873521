/* istanbul ignore file */
import React from 'react';
import LeavesBreadcrumbs from 'erp/leave/shared/LeavesBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';
import { useParams } from 'react-router-dom';

const SingleLeavesBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getSingleEntityUrl } = useLeaveUrl();
  const { id } = useParams<{ id: string }>();

  return (
    <LeavesBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </LeavesBreadcrumbs>
  );
};

export default SingleLeavesBreadcrumbs;
