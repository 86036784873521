/* istanbul ignore file */
import React, { useContext, useMemo } from 'react';
import { store } from 'shared/reducer/store';

export type AuthenticationToken = Nullable<string>;

export interface TokenContext {
  getToken: () => Promise<AuthenticationToken>;
}

export interface DefaultTokenProviderProps {
  getToken: () => Promise<AuthenticationToken>;
  children: React.ReactNode;
}

const TokenReactContext = React.createContext<TokenContext>({
  getToken: () => Promise.resolve((store.getState() as any).authToken?.authenticationToken?.token || null),
});

export const useTokenProvider = () => useContext(TokenReactContext);

export const DefaultTokenProvider = ({ getToken, children }: DefaultTokenProviderProps) => {
  const contextValue = useMemo(
    () => ({
      getToken,
    }),
    [getToken],
  );

  return <TokenReactContext.Provider value={contextValue}>{children}</TokenReactContext.Provider>;
};
