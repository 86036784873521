/* istanbul ignore file */
import React from 'react';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useRoute from 'shared/routing/useRoute';
import { EMPLOYEE_PERFORMANCE_URL } from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Switch, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { CREATE_EMPLOYEE_PERFORMANCE, READ_LIST_EMPLOYEE_PERFORMANCE } from 'erp/employee/employeeService';
import { Route } from 'react-router';
import EmployeePerformanceContext from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceContext';
import { READ, UPDATE } from 'crm/crmService';
import UpdateEmployeePerformancePage from 'erp/employee/performanceEvaluation/employeePerformance/createupdate/Update';
import ShowEmployeePerformancePage from 'erp/employee/performanceEvaluation/employeePerformance/Show/ShowEmployeePerformancePage';
import EmployeePerformanceListPage from 'erp/employee/performanceEvaluation/employeePerformance/List';
import Page404 from 'shared/uibuilder/Page404';
import CreateEmployeePerformancePage from 'erp/employee/performanceEvaluation/employeePerformance/createupdate/Create';

export const EMPLOYEE_PERFORMANCE_ID = 'employeePerformanceId';

export const useEmployeePerformanceRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  return useRoute({
    listRoute: `${employeeRoute}${EMPLOYEE_PERFORMANCE_URL}`,
    singleEntityRoute: `${employeeRoute}${EMPLOYEE_PERFORMANCE_URL}/:${EMPLOYEE_PERFORMANCE_ID}`,
  });
};

export const useEmployeePerformanceUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${employeeLink}${EMPLOYEE_PERFORMANCE_URL}`,
    idSource: EMPLOYEE_PERFORMANCE_ID,
  });
};

export const useEmployeePerformanceId = () => {
  const params: Dictionary<string> = useParams<Dictionary<string>>();
  return params.employeePerformanceId;
};

const EmployeePerformanceRouter = () => {
  const { listRoute, createRoute, updateRoute, singleEntityRoute } = useEmployeePerformanceRoute();
  return (
    <Switch>
      <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_EMPLOYEE_PERFORMANCE}>
        <CreateEmployeePerformancePage />
      </GlobalProtectedRoute>

      <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={READ_LIST_EMPLOYEE_PERFORMANCE}>
        <EmployeePerformanceListPage />
      </GlobalProtectedRoute>

      <Route path={updateRoute} exact>
        <EmployeePerformanceContext permissionToCheck={UPDATE}>
          <UpdateEmployeePerformancePage />
        </EmployeePerformanceContext>
      </Route>

      <Route path={singleEntityRoute} exact>
        <EmployeePerformanceContext permissionToCheck={READ}>
          <ShowEmployeePerformancePage />
        </EmployeePerformanceContext>
      </Route>

      <Route component={Page404} />
    </Switch>
  );
};

export default EmployeePerformanceRouter;
