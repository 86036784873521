/* istanbul ignore file */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Box from 'uibuilder/Box';
import List from '@mui/material/List';
import HeaderMenuSubItem from './HeaderMenuSubItem';
import { IconButton } from 'uibuilder/button';
import MoreHorizIcon from '@mui/icons-material/MoreHorizOutlined';
import MaterialLink from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import useDesktopHeaderMenuItemsService from './useDesktopHeaderMenuItemsService';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { styled } from '@mui/material/styles';
import { HeaderMenuItemProps } from '../headerMenuService';

const StyledDesktopHeaderMenuItems = styled(Box)(({ theme }: { theme: any }) => ({
  display: 'flex',
  alignItems: 'stretch',
  '& .header-menu__container': {
    margin: 0,
    display: 'flex',
    alignItems: 'stretch',
    minWidth: '1px',
  },

  '& .header-menu__item': {
    display: 'inline-flex',
    flexShrink: 0,
    minHeight: '38px',
    position: 'relative',
    '&:hover .header-menu__item-link.active': {
      '& ~ .header-menu__subitems': {
        display: 'block',
      },
    },
    '& .header-menu__subitems': {
      display: 'none',
      position: 'absolute',
      background: theme.vars.palette.background.paper,
      top: 'calc(100% - 22px)',
      left: 0,
      boxShadow: theme.shadows[3],
      borderRadius: 6,
      minWidth: 180,
      zIndex: 1,
      '& .MuiListItem-root': {
        color: theme.vars.palette.text.primary,
        padding: '9px 16px',
        margin: 0,
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: `rgba(${(theme.vars.palette.neutral as any)[0]}, ${theme.vars.palette.action.hoverOpacity})`,
        },
      },
    },
  },
  '& .header-menu__item-link': {
    padding: '21px 15px',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.vars.palette.text.primary,
    textDecoration: 'none',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: theme.vars.palette.action.hover,
    },
    '&.active': {
      fontWeight: 600,
      borderBottomColor: (theme.vars.palette.neutral as any)[0],
    },
    '&::after': {
      content: 'attr(data-text)',
      height: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      userSelect: 'none',
      pointerEvents: 'none',
      fontWeight: 600,
      '@media speech': {
        display: 'none',
      },
    },
  },
}));

const DesktopHeaderMenuItems = () => {
  const { overflowTabs, visibleTabs, containerRef } = useDesktopHeaderMenuItemsService();

  return (
    <StyledDesktopHeaderMenuItems>
      <div className="header-menu__container" ref={containerRef}>
        {visibleTabs.map(({ name, url, exact = false, children }: HeaderMenuItemProps) => (
          <div className="header-menu__item" key={`${url}-${name}`} id={url}>
            <MaterialLink
              component={NavLink}
              className="header-menu__item-link"
              to={url}
              activeClassName="active"
              exact={exact}
              data-text={name || url}
            >
              {name || url}
            </MaterialLink>
            {!!children?.length && (
              <List className="header-menu__subitems">
                {children.map(child => (
                  <HeaderMenuSubItem {...child} pathname={url} />
                ))}
              </List>
            )}
          </div>
        ))}
      </div>

      {visibleTabs.length > 0 && overflowTabs.length > 0 && (
        <DropdownMenu
          popupId="header-menu"
          iconButton={
            <IconButton title="More" aria-label="More" sx={{ flexShrink: 0, alignSelf: 'center' }}>
              <MoreHorizIcon />
            </IconButton>
          }
        >
          {overflowTabs.map(({ name, url, exact = false }: HeaderMenuItemProps) => (
            <MenuItem
              key={url}
              component={NavLink}
              id={url}
              to={url}
              activeClassName="active"
              exact={exact}
              className="header-menu__hidden-item"
              sx={{ color: (theme: any) => theme.vars.palette.text.primary }}
            >
              {name}
            </MenuItem>
          ))}
        </DropdownMenu>
      )}
    </StyledDesktopHeaderMenuItems>
  );
};

export default DesktopHeaderMenuItems;
