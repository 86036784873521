/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import BaseInputErrors from './BaseInputErrors';
import { TogglerInputLayoutProps, TogglerInputLayoutType } from 'shared/uibuilder/form/input/TogglerInput';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

export const StyledSwitch = styled(Switch, {
  shouldForwardProp: prop => prop !== 'dataOn' && prop !== 'dataOff',
})(({ theme, dataOn = '', dataOff = '' }: any) => ({
  width: '65px',
  padding: 8,
  marginRight: '3px',
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 27,
      color: theme.vars?.palette.common.white,
      fontSize: '9px',
      lineHeight: 1,
      textAlign: 'center',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(27px)',
    '& + .MuiSwitch-track': {
      '&:before': {
        content: `"${dataOn}"`,
        left: 10,
      },
    },
  },
  '& .MuiSwitch-switchBase:not(.Mui-checked)': {
    '& + .MuiSwitch-track': {
      '&:after': {
        content: `"${dataOff}"`,
        right: 10,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
})) as any;

const TogglerInputLayout: TogglerInputLayoutType = ({
  label = '',
  value,
  errorMessageMapper,
  onChangeCallback,
  onFocusCallback,
  source,
  isRequired = false,
  disabled = false,
  errorMessages = [],
  color = 'primary',
  checkedValue = 'On',
  uncheckedValue = 'Off',
  className,
  isControlledInput,
}: TogglerInputLayoutProps) => {
  const hasError = !!errorMessages && errorMessages.length > 0;

  return (
    <FormControl
      className={classnames('form-group', className)}
      fullWidth
      error={hasError}
      required={!!isRequired}
      variant="standard"
    >
      <FormControlLabel
        disabled={disabled || false}
        value={String(value)}
        control={
          <StyledSwitch
            color={color as any}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (isControlledInput) {
                event.preventDefault();
              }

              event.stopPropagation();

              if (onChangeCallback) {
                onChangeCallback({
                  [source]: event.target.checked,
                });
              }
            }}
            checked={isControlledInput ? value || value === checkedValue : undefined}
            defaultChecked={value || value === checkedValue}
            dataOn={checkedValue}
            dataOff={uncheckedValue}
            inputProps={{
              onFocus: onFocusCallback,
            }}
          />
        }
        label={
          <>
            {label}
            {isRequired && <span> *</span>}
          </>
        }
        sx={{ marginLeft: '-7px', marginRight: 0 }}
      />
      <FormHelperText>
        {hasError ? <BaseInputErrors errorMessages={errorMessages} errorMessageMapper={errorMessageMapper} /> : null}
      </FormHelperText>
    </FormControl>
  );
};

export default TogglerInputLayout;
