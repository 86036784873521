import { SET_MESSAGE, DISMISS_MESSAGE } from 'shared/message/messageService';
import { Action } from 'redux';

export type MessageContentType = string | React.ReactElement;

export interface MessageProps {
  closeable?: boolean;
  content: MessageContentType;
  status: string;
}

export interface MessageState {
  message?: MessageProps;
  pathname?: string;
  messageContext?: string;
}

interface MessageActions extends Action {
  payload: MessageState;
}

const initialState: MessageState[] = [];

const getDistinctArray = (array: MessageState[]) =>
  array.filter((v, i, a) => a.findIndex(t => JSON.stringify(t) === JSON.stringify(v)) === i);

export default (state = initialState, action: MessageActions = {} as MessageActions) => {
  if (action.type === SET_MESSAGE) {
    const messageContext = action?.payload?.messageContext;
    const newState =
      action.payload.message?.closeable === false
        ? [...state, action.payload]
        : [...state.filter(m => m.message?.closeable === false || m.messageContext !== messageContext), action.payload];

    return getDistinctArray(newState);
  }

  if (action.type === DISMISS_MESSAGE) {
    const message = action?.payload?.message;
    const messageContext = action?.payload?.messageContext;
    return message
      ? state.filter(m => m.messageContext !== messageContext || JSON.stringify(m.message) !== JSON.stringify(message))
      : state.filter(m => m.messageContext !== messageContext);
  }

  return state;
};
