/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import CreateHiringForm from 'erp/recruitment/hiring/createupdate/CreateHiringForm';
import { useHiringUrl } from 'erp/recruitment/hiring/HiringRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import { HIRING_REQUEST_READ } from 'erp/recruitment//hiring/useHiringService';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';
import OneStopPageLayout from 'uibuilder/layout/form/OneStopPageLayout';

const OneStopHiringForm = () => {
  const { getListUrl: getListHiringUrl } = useHiringUrl();
  const { getListUrl: getVacancyListUrl } = useVacancyUrl();
  const { createOneStop, getValidationSchemaOneStop, getInitialData } = useVacancyService();
  const { isGranted } = useAuthorization();

  const isHasReadHiringRequest = isGranted(HIRING_REQUEST_READ);

  return (
    <CreateForm
      submitFormFunc={createOneStop}
      afterSubmit={{
        redirect: isHasReadHiringRequest ? getListHiringUrl() : getVacancyListUrl(),
        message: 'You are awesome! The Hiring request has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchemaOneStop}
      getDataFunc={() => getInitialData(true)}
    >
      <OneStopPageLayout>
        <CreateHiringForm />
      </OneStopPageLayout>
    </CreateForm>
  );
};

export default OneStopHiringForm;
