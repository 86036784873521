/* istanbul ignore file */
// removed from test coverage because it will be need to refactor

// libs
import React from 'react';
import { Switch, useParams } from 'react-router-dom';
// components
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import CandidatesListPage from 'erp/candidate/List';
import CandidateContext from 'erp/candidate/CandidateContext';
import CandidateViewPage from 'erp/candidate/Show';
import CreateCandidatePage from 'erp/candidate/createupdate/Create';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

// services
import {
  CREATE_CANDIDATE,
  READ_CANDIDATES_LIST,
  READ_COMMUNICATION,
  READ_TIMELINE,
  READ_LIST_CANDIDATE_APPLICATIONS,
} from 'erp/candidate/shared/candidateService';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import UpdateCandidatePage from 'erp/candidate/createupdate/Update';
import CandidateTimelinePage from 'erp/candidate/Timeline';
import CandidateIdPage from 'erp/candidate/CandidateIdPage';
import { CandidateOfferRouter } from './offercompensation';
import ShowCandidate from 'erp/candidate/shared/ShowCandidate';
import { READ } from 'crm/crmService';
import CandidateCommunicationPage from 'erp/candidate/communication';
import CandidateApplicationsList from 'erp/candidate/applications/List';
import CreateCandidateApplicationFromJobBoardPage from 'erp/candidate/applications/createupdate/Create';
import { CANDIDATE_APPLICATION_CREATE } from 'erp/candidate/applications/shared/candidateApplicationService';

export const CANDIDATES_PATH = '/candidates';

export const useCandidateRoute = () => {
  const routes = useRoute({ listRoute: CANDIDATES_PATH, editPostfix: 'profile/edit' });

  return {
    ...routes,
    profileRoute: `${routes.singleEntityRoute}/profile`,
    offersRoute: `${routes.singleEntityRoute}/offers`,
    communicationRoute: `${routes.singleEntityRoute}/communication/:timelineEntryId?`,
    createCandidateApplicationFromJobBoardRoute: `${routes.listRoute}/applications/add/job-board`,
    applicationsRoute: `${routes.singleEntityRoute}/applications`,
  };
};

export const useCandidateUrl = () => {
  const entityUrl = useEntityUrl({
    baseLink: CANDIDATES_PATH,
    editPostfix: 'profile/edit',
  });

  const { getSingleEntityUrl } = entityUrl;

  return {
    ...entityUrl,
    getProfileUrl: (id?: string) => `${getSingleEntityUrl(id)}/profile`,
    getProfileEditUrl: (id: StringOrNumber) => `${getSingleEntityUrl(id)}/profile/edit`,
    getCommunicationUrl: (id: StringOrNumber) => `${getSingleEntityUrl(id)}/communication`,
    getApplicationsUrl: (id: StringOrNumber) => `${getSingleEntityUrl(id)}/applications`,
  };
};

export const useCandidateId = () => {
  const params: Dictionary<string> = useParams<Dictionary<string>>();

  return params.id;
};

const CandidateRouter = () => {
  const {
    createRoute,
    createCandidateApplicationFromJobBoardRoute,
    listRoute,
    singleEntityRoute,
    updateRoute,
    timelineRoute,
    profileRoute,
    offersRoute,
    communicationRoute,
    applicationsRoute,
  } = useCandidateRoute();

  return (
    <ProtectedRouter basePath={CANDIDATES_PATH}>
      <Switch>
        <GlobalProtectedRoute path={listRoute} exact globalPermissionToCheck={[READ_CANDIDATES_LIST]}>
          <CandidatesListPage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute path={createRoute} exact globalPermissionToCheck={CREATE_CANDIDATE}>
          <CreateCandidatePage />
        </GlobalProtectedRoute>
        <GlobalProtectedRoute
          path={createCandidateApplicationFromJobBoardRoute}
          exact
          globalPermissionToCheck={CANDIDATE_APPLICATION_CREATE}
        >
          <CreateCandidateApplicationFromJobBoardPage showBreadcrumbs />
        </GlobalProtectedRoute>
        <Route path={singleEntityRoute} exact>
          <CandidateContext permissionToCheck="READ">
            <CandidateIdPage />
          </CandidateContext>
        </Route>
        <Route path={profileRoute} exact>
          <CandidateContext permissionToCheck="READ">
            <CandidateViewPage />
          </CandidateContext>
        </Route>
        <Route path={updateRoute} exact>
          <CandidateContext permissionToCheck="UPDATE">
            <UpdateCandidatePage />
          </CandidateContext>
        </Route>
        <Route path={timelineRoute} exact>
          <CandidateContext permissionToCheck={[READ_TIMELINE]}>
            <CandidateTimelinePage />
          </CandidateContext>
        </Route>
        <Route path={offersRoute}>
          <CandidateContext permissionToCheck={READ}>
            <ShowCandidate>
              <CandidateOfferRouter />
            </ShowCandidate>
          </CandidateContext>
        </Route>
        <Route path={communicationRoute}>
          <CandidateContext permissionToCheck={READ_COMMUNICATION}>
            <ShowCandidate>
              <CandidateCommunicationPage />
            </ShowCandidate>
          </CandidateContext>
        </Route>
        <Route path={applicationsRoute}>
          <CandidateContext permissionToCheck={READ_LIST_CANDIDATE_APPLICATIONS}>
            <ShowCandidate>
              <CandidateApplicationsList />
            </ShowCandidate>
          </CandidateContext>
        </Route>
        <Route component={Page404} />
      </Switch>
    </ProtectedRouter>
  );
};

CandidateRouter.getRouterPath = () => CANDIDATES_PATH;

export default CandidateRouter;
