/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import Show from 'shared/uibuilder/show/Show';
import useProjectService from 'erp/project/shared/projectService';
import List from 'shared/uibuilder/list/List';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import { useParams } from 'react-router-dom';
import ProjectEntityHeader from 'erp/project/shared/ProjectEntityHeader';
import ViewProjectPageMenu from 'erp/project/Show/ViewProjectPageMenu';
import ProjectDashboardCyclePeriodField from '../shared/field/ProjectDashboardCyclePeriodField';
import ProjectDashboardBreadcrumbs from '../shared/ProjectDashboardBreadcrumbs';
import useProjectDashboardService from '../ProjectDashboardService';
import ProjectDashboardDateField from '../shared/field/ProjectDashboardDateField';

const ViewProjectDashboard = () => {
  const { id: projectId } = useParams<Dictionary<string>>();
  const { search } = useProjectDashboardService();
  const { getById } = useProjectService();

  return (
    <Show getDataMethod={() => getById(projectId)}>
      <ShowPageLayout
        entityHeader={<ProjectEntityHeader />}
        menu={<ViewProjectPageMenu />}
        breadcrumbs={<ProjectDashboardBreadcrumbs />}
      >
        <List getDataMethod={search}>
          <RelationListLayout header="Dashboard">
            <DataGridLayout isSmallTable showTotal={false}>
              <ProjectDashboardCyclePeriodField
                sourceDateFrom="from"
                sourceDateTo="to"
                sourceCycleStatus="cycleStatus"
                label="Weekly cycle period"
                width={30}
              />
              <ProjectDashboardDateField
                source="demoDate"
                sourceOnTime="demoOnTime"
                sourceCycleStatus="cycleStatus"
                label="Demo date"
                width={15}
              />
              <ProjectDashboardDateField
                source="reportDate"
                sourceOnTime="reportOnTime"
                sourceCycleStatus="cycleStatus"
                label="Report date"
                width={15}
              />
            </DataGridLayout>
          </RelationListLayout>
        </List>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewProjectDashboard;
