/* istanbul ignore file */
import React, { useContext, useMemo } from 'react';
import { store } from 'shared/reducer/store';
import { AuthenticationToken } from 'authentication/DefaultTokenProvider';

export interface AuthenticationContext {
  getUserAlias: () => Nullable<string>;
  isAuthenticated: boolean;
  getToken: () => AuthenticationToken;
}

export interface AuthenticationProviderProps {
  userAlias: Nullable<string>;
  isAuthenticated: boolean;
  getToken: () => AuthenticationToken;
  children: React.ReactNode;
}

const AuthenticationReactContext = React.createContext<AuthenticationContext>({
  getUserAlias: () => null,
  isAuthenticated: false,
  getToken: () => (store.getState() as any).authToken?.authenticationToken?.token || null,
});

export const useAuthenticationProvider = () => useContext(AuthenticationReactContext);

export const AuthenticationProvider = ({
  getToken,
  isAuthenticated,
  userAlias,
  children,
}: AuthenticationProviderProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserAlias = () => userAlias;

  const contextValue = useMemo(
    () => ({
      getToken,
      isAuthenticated,
      getUserAlias,
    }),
    [getToken, getUserAlias, isAuthenticated],
  );

  return <AuthenticationReactContext.Provider value={contextValue}>{children}</AuthenticationReactContext.Provider>;
};
