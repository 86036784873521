import { get, set, cloneDeep } from 'lodash';
import assignmentValidation from 'erp/assignment/createupdate/assignmentValidation';
import { useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { ResourceData } from 'shared/crud/baseCrudService';
import { FilterCondition } from 'shared/uibuilder/list/filter/FilterContext';

export const ASSIGNMENT_PATH = '/assignments';
export const ASSIGNMENT_DELETE_MESSAGE = 'Do you really want to delete this assignment?';

export const READ_ASSIGNMENTS_LIST = 'READ_RESOURCE_ASSIGNMENT_LIST';
export const READ_EMPLOYEE_ASSIGNMENTS = 'READ_EMPLOYEE_ASSIGNMENTS';
export const READ_ASSIGNMENT = 'READ';
export const CREATE_ASSIGNMENT = 'CREATE_RESOURCE_ASSIGNMENT';
export const UPDATE_ASSIGNMENT = 'UPDATE';
export const DELETE_ASSIGNMENT = 'DELETE';
export const FE_DELETE_ASSIGNMENTS = 'RESOURCE_ASSIGNMENT_FE_DELETE';

export const ACTIVE_STATUS = 'ACTIVE';
export const FUTURE_STATUS = 'FUTURE';

export enum AssignmentType {
  INDIVIDUAL_ASSIGNMENT = 'INDIVIDUAL_ASSIGNMENT',
  GROUP_ASSIGNMENT = 'GROUP_ASSIGNMENT',
}

export const AssignmentTypeLabels: Dictionary<string> = {
  [AssignmentType.INDIVIDUAL_ASSIGNMENT]: 'Individual assignment',
  [AssignmentType.GROUP_ASSIGNMENT]: 'Service assignment',
};

const STATUS_FILTER_KEY = 'filter.status.in';
const END_DATE_FILTER_KEY = 'filter.to';
const START_DATE_FILTER_KEY = 'filter.from';

const useAssignmentService = () => {
  const singleResourceUrl = '/resourceAssignments/:id';
  const {
    getById,
    search: baseSearch,
    ...baseCrudRequests
  } = useCrudService({
    singleResourceUrl,
    listResourceUrl: '/resourceAssignments',
    apiService: useKernelApi,
  });
  const { getMaxDate, getMinDate, getToday, formatDateForAPI, getMomentDateObj } = useDateService();
  const today = getToday();
  const tomorrow = getToday().add(1, 'days');

  const { sendPostRequest } = useKernelApi();

  const getValidationSchema = () => Promise.resolve(assignmentValidation);

  const createAssignment = async (assignment: any) => {
    const response = await sendPostRequest('/resourceAssignments', assignment);
    return response.json();
  };

  const getAssignmentById = async (id: string) => {
    const data = await getById(id);
    const minPercentValue = get(data, 'minPercent');
    const maxPercentValue = get(data, 'maxPercent');

    return {
      ...data,
      projectId: get(data, 'project.id'),
      projectName: get(data, 'project.name'),
      positionId: get(data, 'position.id'),
      minPercent: minPercentValue || minPercentValue === 0 ? String(minPercentValue) : minPercentValue,
      maxPercent: maxPercentValue || maxPercentValue === 0 ? String(maxPercentValue) : maxPercentValue,
    };
  };

  const search = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const hasFilterByActiveStatus = get(request, STATUS_FILTER_KEY)?.includes(ACTIVE_STATUS);
    const hasFilterByFutureStatus = get(request, STATUS_FILTER_KEY)?.includes(FUTURE_STATUS);

    ['bench'].forEach(source => {
      const filterValue = searchParams.filter?.[source]?.eq || '';

      if (filterValue && searchParams.filter) {
        searchParams.filter[source] = {
          eq: filterValue === 'YES',
        };
      }
    });

    if (hasFilterByActiveStatus && hasFilterByFutureStatus) {
      const filterKey = `${END_DATE_FILTER_KEY}.${FilterCondition.GE}`;
      const newFilterEndDate = getMaxDate([today, getMomentDateObj(get(request, filterKey) || today)]);

      set(searchParams, filterKey, formatDateForAPI(newFilterEndDate));
    } else if (hasFilterByActiveStatus) {
      const startDateFilterKey = `${START_DATE_FILTER_KEY}.${FilterCondition.LE}`;
      const endDatFilterKey = `${END_DATE_FILTER_KEY}.${FilterCondition.GE}`;

      const newFilterEndDate = getMaxDate([today, getMomentDateObj(get(request, endDatFilterKey) || today)]);
      const newFilterStartDate = getMinDate([today, getMomentDateObj(get(request, startDateFilterKey) || today)]);

      set(searchParams, startDateFilterKey, formatDateForAPI(newFilterStartDate));
      set(searchParams, endDatFilterKey, formatDateForAPI(newFilterEndDate));
    } else if (hasFilterByFutureStatus) {
      const filterKey = `${START_DATE_FILTER_KEY}.${FilterCondition.GE}`;
      const newFilterEndDate = getMaxDate([tomorrow, getMomentDateObj(get(request, filterKey) || tomorrow)]);

      set(searchParams, filterKey, formatDateForAPI(newFilterEndDate));
    }

    delete searchParams.filter.status;

    return baseSearch(searchParams);
  };

  return {
    getAssignmentById,
    getById,
    getValidationSchema,
    createAssignment,
    search,
    ...baseCrudRequests,
  };
};

export default useAssignmentService;
