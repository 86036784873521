import React from 'react';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useHiringService, {
  HIRING_REQUEST_POSITION_REOPEN,
  HiringRequest,
  POSITION_STATUS,
  RequestPosition,
} from '../../useHiringService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

type ReopenHiringPositionButtonProps = {
  allShowData: HiringRequest;
  parentShowSetData: (data: HiringRequest) => void;
};

const ReopenHiringPositionButton: React.FC<ReopenHiringPositionButtonProps> = ({ allShowData, parentShowSetData }) => {
  const { reopenPosition } = useHiringService();
  const { data } = useShowContext();
  const positionData: RequestPosition = data.getData() as RequestPosition;

  if (positionData.positionStatus !== POSITION_STATUS.CLOSED) return null;

  const handleReopenPosition = async () => {
    const updatedData = {
      ...allShowData,
      requestPositions: allShowData.requestPositions.map(position =>
        position.id === positionData.id ? { ...position, positionStatus: POSITION_STATUS.OPEN } : position,
      ),
    } as HiringRequest;

    const response = await reopenPosition(updatedData, positionData.id);
    parentShowSetData(response);
  };

  return (
    <ButtonWithConfirmation
      permissionToCheck={HIRING_REQUEST_POSITION_REOPEN}
      displayMessage="Do you really want to reopen this Position?"
      submitMethod={handleReopenPosition}
      afterSubmit={{
        successMessage: 'Position reopened successfully!',
        errorMessage: "Position couldn't be reopened!",
      }}
    >
      Reopen
    </ButtonWithConfirmation>
  );
};

export default ReopenHiringPositionButton;
