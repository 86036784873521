/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
// components
import DeleteButton from 'uibuilder/button/DeleteButton';
// services
import { useContactUrl } from 'crm/contact/ContactRouter';
import useContactService from 'crm/contact/shared/contactService';
import { ButtonProps } from '../../../../uibuilder/button/Button';

const deleteMessage = 'Do you really want to delete this contact?';

const DeleteContactButton = (props: ButtonProps) => {
  const { id: contactId } = useParams<Dictionary<string>>();
  const { getListUrl } = useContactUrl();
  const { deleteById } = useContactService();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(contactId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Contact has been successfully deleted.',
        errorMessage: `Can't delete contact`,
      }}
      {...props}
    />
  );
};

export default DeleteContactButton;
