import { useParams } from 'react-router-dom';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl, { UrlParams } from 'shared/routing/useEntityUrl';
import useFeatureToggle, { Features } from 'featuretoggle';

export const IsOneStopHistoryEnabled = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  return isFeatureEnabled(Features.ONE_STOP_HISTORY);
};

export const ONE_STOP_ROOT_PATH = '/one-stop';
export const ONE_STOP_PATH = IsOneStopHistoryEnabled() ? `${ONE_STOP_ROOT_PATH}/requests` : ONE_STOP_ROOT_PATH;
export const SUPPORT_REQUEST_ID = 'SUP01';
export const FEEDBACK_ABOUT_EMPLOYEE = 'Leave feedback for colleague';
export const CONTACT_SUPPORT = 'Contact support';
export const ICON_NOTE = 'icon-note';

export const useOneStopRequestId = () => {
  const urlParams: UrlParams = useParams<Dictionary<string>>();

  return urlParams.id;
};

export const useOneStopRoute = () => {
  const baseRoutes = useRoute({ listRoute: ONE_STOP_PATH });
  const { singleEntityRoute: oldSingleEntityRoute } = useRoute({ listRoute: ONE_STOP_ROOT_PATH });
  const { singleEntityRoute: oldSingleHistoryRoute } = useRoute({ listRoute: `${ONE_STOP_ROOT_PATH}/history` });

  return {
    ...baseRoutes,
    oldSingleEntityRoute,
    oldSingleHistoryRoute,
  };
};

export const useOneStopRequestsUrl = () => {
  const entityUrl = useEntityUrl({
    baseLink: ONE_STOP_PATH,
  });

  return {
    ...entityUrl,
  };
};

export const useSupportRequestUrl = () => {
  const { getSingleEntityUrl } = useOneStopRequestsUrl();

  return {
    supportUrl: getSingleEntityUrl(SUPPORT_REQUEST_ID),
  };
};

export const useFeedbackRequestUrl = (formId?: string) => {
  const { getSingleEntityUrl } = useOneStopRequestsUrl();

  return {
    supportUrl: getSingleEntityUrl(formId),
  };
};
