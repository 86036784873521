/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import Tree from 'shared/uibuilder/Tree/index';
import { TreeNodeProps } from 'shared/uibuilder/Tree/treeHelpers';
import {
  FE_READ,
  hasAccessToAnyAction,
  hasAccessToCreateAction,
  hasAccessToEditAction,
  RbsNodeType,
} from 'erp/rbs/shared/rbsService';
import RbsNodeTooltip from 'erp/rbs/shared/tooltip/RbsNodeTooltip';
import TreeActions from 'erp/rbs/shared/tree/TreeActions';
import { useRbsUrl } from '../../RbsRouter';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import EmployeeLink from 'erp/employee/shared/EmployeeLink';
import useAuthorization from 'shared/authorization/authorizationService';
import NameField, { NameFieldValue } from 'shared/uibuilder/field/NameField';
import { GET_EMPLOYEES_LIST } from 'erp/employee/employeeService';
import Loading from 'shared/uibuilder/Loading';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAsyncValue from 'shared/asyncHelper';
import { UrlParams } from 'shared/routing/useEntityUrl';
import './RbsTree.scss';

export type TooltipNameData = {
  alias?: string;
  nameEn?: NameFieldValue;
};

const RbsTree = ({
  height,
  nodeChildren = () => null,
  permissionToCheck = [],
}: {
  height?: number;
  nodeChildren?: (rd3tNodeProps: TreeNodeProps) => React.ReactNode;
  permissionToCheck?: string | string[];
}) => {
  const { data, loading } = useListContext();
  const { getSingleEntityUrl } = useRbsUrl();
  const { isGranted } = useAuthorization();
  const canViewEmployeeDetails = isGranted(GET_EMPLOYEES_LIST);
  const hasViewReadPermission = isGranted(FE_READ);
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(() => getUserAlias());
  const params: UrlParams = useParams<Dictionary<string>>();

  const onNodeClick = (nodeData: TreeNodeProps) => {
    if (
      hasAccessToAnyAction(hasViewReadPermission, nodeData, userAlias) &&
      params?.id?.toString() !== nodeData?.id?.toString()
    ) {
      window.open(getSingleEntityUrl(nodeData.id), '_blank');
    }
  };

  if (loading) {
    return (
      <div className="tree-loader-wrapper">
        <Loading />
      </div>
    );
  }

  return (
    <Tree
      data={data?.items || []}
      height={height}
      onNodeClick={onNodeClick}
      nodeChildren={(rd3tNodeProps: TreeNodeProps) => {
        const { name, id, attributes: { owner, secondOwner } = {}, nodeType } = rd3tNodeProps;
        const { alias, nameEn } = (owner as TooltipNameData) || {};
        const { alias: secondOwnerAlias, nameEn: secondOwnerNameEn } = (secondOwner as TooltipNameData) || {};
        const hasAccessToEdit = hasAccessToEditAction(rd3tNodeProps, userAlias);
        const hasAccessToCreate = hasAccessToCreateAction(rd3tNodeProps, userAlias);
        const getPermissions = hasAccessToEdit || hasAccessToCreate ? null : permissionToCheck;

        return (
          <>
            <div className="d-flex w-100 align-items-start">
              <span className="tree-node-name">{name}</span>
              <TreeActions
                buttons={nodeChildren(rd3tNodeProps)}
                permissionToCheck={getPermissions}
                isVisible={
                  (nodeType === RbsNodeType.PROJECT && getPermissions != null) || hasAccessToEdit || hasAccessToCreate
                }
              />
            </div>
            <div className="d-flex justify-content-between w-100 align-items-center">
              <span className="tree-node-owner">
                <EmployeeLink
                  id={canViewEmployeeDetails ? alias : null}
                  name={<NameField value={nameEn} ignoredNameParts={['maidenName']} />}
                />
                <EmployeeLink
                  id={canViewEmployeeDetails ? secondOwnerAlias : null}
                  name={<NameField value={secondOwnerNameEn} ignoredNameParts={['maidenName']} />}
                />
              </span>
              <RbsNodeTooltip {...rd3tNodeProps} source={String(id)} />
            </div>
          </>
        );
      }}
    />
  );
};

export default RbsTree;
