import { useEffect, useState } from 'react';
import momentTimezone from 'moment-timezone';
import useApplicationSettings from 'shared/uibuilder/applicationSettingsContext';
import { DATE_FORMAT, DAY_START, DEFAULT_TIMEZONE } from 'shared/uibuilder/dateService/constants';

const useDateTimeService = (overriddenTimezone?: string) => {
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  const { getTimezone } = useApplicationSettings();

  useEffect(() => {
    (async () => {
      if (getTimezone) {
        const userTimezone = await getTimezone();
        if (userTimezone) {
          setTimezone(userTimezone);
        }
      }
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param dateUTC - text date in UTC+0
   * @returns {moment.Moment} - text date converted to user timezone or overriddenTimezone
   */
  const getDateInCurrentTimezone = (dateUTC: momentTimezone.MomentInput) => {
    return momentTimezone.utc(dateUTC).tz(overriddenTimezone || timezone);
  };

  const getLocalDate = (date: any) => momentTimezone.tz(date, timezone);

  const getCurrentTimezoneDateInUtc = (timezoneDate: momentTimezone.MomentInput) => {
    return momentTimezone(timezoneDate)
      .tz(overriddenTimezone || timezone, true)
      ?.utc();
  };

  /**
   * @param dateUTC - text date in UTC+0
   * @param format - date format like YYYY-MM-DD
   * @returns {string|null} - text date converted to user timezone or overriddenTimezone
   */
  const formatDateWithTimezone = (dateUTC: momentTimezone.MomentInput, format: string | undefined) => {
    if (!dateUTC) return null;

    const localDate = getDateInCurrentTimezone(dateUTC);

    return ` ${localDate?.format(format)}`;
  };

  /**
   * @param dateUTC - text date in UTC+0
   * @returns {string|undefined} - text date converted to user timezone or overriddenTimezone in full date format
   */
  const formatDateWithFullFormat = (dateUTC: momentTimezone.MomentInput) => {
    return formatDateWithTimezone(dateUTC, DATE_FORMAT.FULL);
  };

  /**
   * @param dateUTC - text date in UTC+0
   * @returns {string|undefined} - text date converted to user timezone or overriddenTimezone in full date format
   */
  const formatDateWithFullDateFormat = (dateUTC: momentTimezone.MomentInput) => {
    return formatDateWithTimezone(dateUTC, DATE_FORMAT.FULL_DATE);
  };

  /**
   * @param dateText - text date
   * @param edge - what part of the day to take, can be 'end' or 'start'. 'start' by default
   * @returns {string|undefined} - text date converted to user timezone or overriddenTimezone
   */
  const formatDateForAPI = (dateText: any, edge = DAY_START) => {
    // @ts-ignore
    return momentTimezone.tz(dateText, timezone)[`${edge}Of`]('day').utc().format(DATE_FORMAT.API);
  };

  const formatDateTimeForAPI = (dateTimeText: any) =>
    momentTimezone.tz(dateTimeText, timezone).utc().format(DATE_FORMAT.API);

  /**
   * @param dateUTC - text date in UTC+0
   * @returns {string|undefined} - a string indicating how much time has passed, converted in the current timezone
   */
  const fromNow = (dateUTC: momentTimezone.MomentInput) => {
    const localDate = getDateInCurrentTimezone(dateUTC);

    return ` ${localDate.fromNow()}`;
  };

  return {
    getLocalDate,
    formatDateWithTimezone,
    formatDateForAPI,
    formatDateTimeForAPI,
    getDateInCurrentTimezone,
    getCurrentTimezoneDateInUtc,
    fromNow,
    formatDateWithFullFormat,
    formatDateWithFullDateFormat,
    getTimezone,
  };
};

export default useDateTimeService;
