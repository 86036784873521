import { useParams } from 'react-router-dom';
import { useKernelApi } from 'api';
import useLeadService, { RESOURCE_URL } from 'crm/lead/shared/leadService';
import { ACCOUNT_TYPE } from 'crm/account/shared/input/AccountInput';
import convertLeadValidation from 'crm/lead/Convert/convertLeadValidation';
import useCrudService from 'shared/crud';

const getModifiedDataForLeadConvert = (data: any) => {
  const result = { ...data };

  switch (result.accountType) {
    case ACCOUNT_TYPE.NEW:
      result.accountId = null;
      break;
    case ACCOUNT_TYPE.EXISTING:
      result.accountId = result.existingAccountName;
      break;
    default:
  }

  if (result.createOpportunity) {
    delete result.opportunityName;
    delete result.closeDate;
  }

  // delete properties that aren't needed for the request
  delete result.createOpportunity;
  delete result.accountType;
  delete result.existingAccountName;

  return result;
};

/**
 * Convert Lead service.
 */

const useConvertLeadService = () => {
  const { getById } = useLeadService();
  const { id: leadId } = useParams<Dictionary<string>>();
  const { sendPostRequest } = useKernelApi();
  const { invalidateCache } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const convertLead = async (id: StringOrNumber, data: any) => {
    const modifiedData = getModifiedDataForLeadConvert(data);
    const url = `${RESOURCE_URL}/${id}/convert`;
    const response = await sendPostRequest(url, modifiedData);

    if (invalidateCache) {
      invalidateCache();
    }

    return response.json();
  };

  const getConvertValidationSchema = () => Promise.resolve(convertLeadValidation);

  const getInitialData = async () => {
    const data = await getById(leadId);

    return data;
  };

  return {
    convertLead,
    getInitialData,
    getConvertValidationSchema,
  };
};

export default useConvertLeadService;
