/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useParams } from 'react-router-dom';
import { useVacancyPublicationUrl } from 'erp/recruitment/vacancy/publication/VacancyPublicationRouter';
import VacancyPublicationBreadcrumbs from 'erp/recruitment/vacancy/publication/shared/VacancyPublicationBreadcrumbs';

const SingleVacancyPublicationBreadcrumbs = ({ children }: any) => {
  const { getSingleEntityUrl } = useVacancyPublicationUrl();
  // @ts-ignore
  const { id } = useParams<Dictionary<string>>();

  return (
    <VacancyPublicationBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </VacancyPublicationBreadcrumbs>
  );
};

export default SingleVacancyPublicationBreadcrumbs;
