import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import React from 'react';
import { ResourceId } from 'shared/crud/baseCrudService';
import { Permission } from 'shared/authorization/authorizationService';
import UrlTokenProvider from './UrlTokenProvider';

export type Service = {
  getPermissions: () => Permission[];
};

export type TmpAccessAwareSecurityContextProps = {
  children: React.ReactNode | React.ReactNode[];
  contextName: string;
  // eslint-disable-next-line react/require-default-props
  id?: ResourceId;
  // eslint-disable-next-line react/require-default-props
  idSource?: string;
  permissionToCheck: Permission | Permission[] | null;
  useEntityService: () => Service;
};

const Wrapper = ({ useEntityService, ...props }: TmpAccessAwareSecurityContextProps) => {
  const { getPermissions } = useEntityService();

  return <SecurityContextTemplate getPermissionsFunc={getPermissions} {...props} />;
};

const TmpAccessAwareSecurityContext = (props: TmpAccessAwareSecurityContextProps) => {
  return (
    <UrlTokenProvider>
      <Wrapper {...props} />
    </UrlTokenProvider>
  );
};

export default TmpAccessAwareSecurityContext;
