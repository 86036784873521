import React from 'react';
import useCommonSkillsService from 'erp/candidate/commonskills/commonSkillsService';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { Checkbox, DateInput, InputList, NumberInput, TextInput } from 'shared/uibuilder/form/input';
import VacancySpecializationDropdown from 'erp/recruitment/vacancy/shared/input/VacancySpecializationDropdown';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { COMPETENCY_LEVEL } from 'erp/recruitment/vacancy/shared/vacancyService';
import RowGroupInputsWrapper from 'shared/uibuilder/form/input/RowGropInputsWrapper/RowGroupInputsWrapper';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import { HIRING_MODE } from '../useHiringService';
import VacancyDetailsListInput from 'erp/recruitment/vacancy/shared/input/VacancyDetailsListInput';
import VacancyDetailsInput from 'erp/recruitment/vacancy/shared/input/VacancyDetailsInput';
import ExpectedCompensationListInput from 'erp/recruitment/vacancy/shared/input/ExpectedCompensationListInput';
import ExpectedCompensationInput from 'erp/recruitment/vacancy/shared/input/ExpectedCompensationInput';
import VacancyWysiwygInput from 'erp/recruitment/vacancy/shared/input/VacancyWysiwygInput';
import VacancyResponsibilityInput from 'erp/recruitment/vacancy/shared/input/VacancyResponsibilityInput';
import VacancyRequirementInput from 'erp/recruitment/vacancy/shared/input/VacancyRequirementInput';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';
import VacancyPersonalityInput from 'erp/recruitment/vacancy/shared/input/VacancyPersonalityInput';
import VacancyDropdown from 'erp/candidate/shared/input/VacancyDropdown';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import BigFormRow from '../../../../shared/layout/form/BigFormRow';

const CreateHiringForm = () => {
  const titleStyle = { fontSize: 'large' };
  const nameDescriptionInputDefaultValue = { name: null };
  const { searchByName } = useCommonSkillsService();

  const isUseExistingVacancy = (data: FormFieldsData) => data.useExistingVacancy;

  // TODO: temporary logs
  [
    VacancyDropdown,
    VacancySpecializationDropdown,
    VacancyDetailsListInput,
    ExpectedCompensationListInput,
    VacancyWysiwygInput,
    InputList,
    SkillsInput,
    VacancyRequirementInput,
    VacancyPersonalityInput,
    EnumDropdown,
    RowGroupInputsWrapper,
    VacancyDetailsInput,
    ExpectedCompensationInput,
    VacancyResponsibilityInput,
  ].forEach((comp, i) => {
    if (!comp) {
      // eslint-disable-next-line no-console
      console.error('There are no component with number', i);
    }
  });

  return (
    <div className="vacancy-form__wrapper">
      <FormSection title="Main Information">
        <BigFormRow>
          <Checkbox label="Create hiring request for existing vacancy?" source="useExistingVacancy" />
        </BigFormRow>
        <FormRow>
          <ClearAfterUnmountWrapper isVisible={(data: FormFieldsData) => isUseExistingVacancy(data)}>
            <VacancyDropdown source="vacancy" />
          </ClearAfterUnmountWrapper>
        </FormRow>
        <FormRow>
          <Checkbox source="isAsap" defaultValue={false} label="Asap?" />
        </FormRow>
        <FormRow>
          <VacancySpecializationDropdown
            source="aliasPrefix"
            tooltip='Select "Other" and enter the name of the desired position if it is not listed.'
          />
          <TextInput source="name" label="Specialization name" isVisible={vacancy => vacancy.aliasPrefix === 'OTH'} />
          <EnumDropdown
            source="competencyLevel"
            options={COMPETENCY_LEVEL}
            label="Competency level"
            placeholder="Select Competency level"
          />
          <RowGroupInputsWrapper isRequired wrapperLabel="People to hire">
            <NumberInput className="group-input-label" source="positionsMin" label="Min" />
            <NumberInput className="group-input-label" source="openPositions" label="Max" />
          </RowGroupInputsWrapper>
        </FormRow>
        <FormRow>
          <ClearAfterUnmountWrapper isVisible={data => !data.isAsap}>
            <DateInput source="targetDate" label="Target hiring date" />
          </ClearAfterUnmountWrapper>
          <DateInput source="expirationDate" label="Expiration date" />
        </FormRow>
        <FormRow>
          <EnumDropdown
            tooltip={
              <>
                Active hiring mode means that a new position is available and the person is needed to fill it.
                <br />
                Bar raiser means that no additional positions are available, but the staffing coordinator will replace
                low-performers with the hired person.
              </>
            }
            options={HIRING_MODE}
            source="hiringMode"
            label="Hiring Mode"
            placeholder="Select Mode"
          />
        </FormRow>
      </FormSection>
      <FormSection
        title="Office Details"
        subtitle="Should be at least one office details"
        isVisible={(data: FormFieldsData) => !isUseExistingVacancy(data)}
      >
        <Checkbox source="vacancyDetailsToDefine" label="I don't know office details" />
        <FormRow colMd={12} colXl={12}>
          <ClearAfterUnmountWrapper isVisible={vacancy => !vacancy.vacancyDetailsToDefine}>
            <VacancyDetailsListInput
              source="vacancyDetails"
              maxFields={10}
              addText="Add Office Details"
              inputTemplate={VacancyDetailsInput}
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>
      <FormSection title="Expected Compensation" isVisible={(data: FormFieldsData) => !isUseExistingVacancy(data)}>
        <Checkbox source="compensationToDefine" label="I don't know compensation details" />
        <FormRow colMd={12} colXl={12}>
          <ClearAfterUnmountWrapper isVisible={vacancy => !vacancy.compensationToDefine}>
            <ExpectedCompensationListInput
              source="expectedCompensation"
              inputTemplate={ExpectedCompensationInput}
              addText="Add Expected Compensation"
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>
      <FormSection title="Project Description" isVisible={(data: FormFieldsData) => !isUseExistingVacancy(data)}>
        <ClearAfterUnmountWrapper>
          <VacancyWysiwygInput source="description.projectText" label="" />
        </ClearAfterUnmountWrapper>
      </FormSection>
      <FormSection
        title="Responsibilities"
        titleStyle={titleStyle}
        subtitle="Should be at least one responsibility"
        isVisible={(data: FormFieldsData) => !isUseExistingVacancy(data)}
      >
        <ClearAfterUnmountWrapper>
          <InputList
            source="description.responsibilities"
            maxFields={100}
            addText="Add Responsibility"
            inputTemplate={VacancyResponsibilityInput}
            defaultValue={nameDescriptionInputDefaultValue}
          />
        </ClearAfterUnmountWrapper>
      </FormSection>
      <FormSection
        title="Mandatory Requirements"
        titleStyle={titleStyle}
        subtitle="Should be at least one requirement"
        isVisible={(data: FormFieldsData) => !isUseExistingVacancy(data)}
      >
        <ClearAfterUnmountWrapper>
          <InputList
            source="description.requirements"
            maxFields={100}
            addText="Add Mandatory Requirements"
            inputTemplate={VacancyRequirementInput}
            defaultValue={nameDescriptionInputDefaultValue}
            helpText="Mandatory requirenments."
          />
        </ClearAfterUnmountWrapper>
        <MediumFormRow>
          <ClearAfterUnmountWrapper>
            <SkillsInput
              source="description.mandatorySkills"
              loadSuggestionsMethod={searchByName}
              label="Skills:"
              helpText="Required skills for the Vacancy. These skills will be used for Candidate matching purposes and will not be publicly displayed."
            />
          </ClearAfterUnmountWrapper>
          <NumberInput source="minExperience" label="Required Experience" helpText="Required experience in years." />
        </MediumFormRow>
      </FormSection>
      <FormSection
        title="Optional Requirements"
        titleStyle={titleStyle}
        isVisible={(data: FormFieldsData) => !isUseExistingVacancy(data)}
      >
        <ClearAfterUnmountWrapper>
          <InputList
            source="description.optionalRequirements"
            maxFields={100}
            addText="Add Optional Requirements"
            inputTemplate={VacancyRequirementInput}
            defaultValue={nameDescriptionInputDefaultValue}
            helpText="Optional requirenments."
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper>
          <SkillsInput
            source="description.optionalSkills"
            loadSuggestionsMethod={searchByName}
            label="Skills:"
            helpText="Optional skills for the Vacancy. These skills will be used for Candidate matching purposes and will not be publicly displayed."
          />
        </ClearAfterUnmountWrapper>
      </FormSection>
      <FormSection
        title="Personality"
        titleStyle={titleStyle}
        isVisible={(data: FormFieldsData) => !isUseExistingVacancy(data)}
      >
        <ClearAfterUnmountWrapper>
          <InputList
            source="description.personality"
            maxFields={100}
            addText="Add Trait"
            inputTemplate={VacancyPersonalityInput}
            defaultValue={nameDescriptionInputDefaultValue}
          />
        </ClearAfterUnmountWrapper>
      </FormSection>
    </div>
  );
};

export default CreateHiringForm;
