import React from 'react';
import { useHiringId } from 'erp/recruitment/hiring/HiringRouter';
import useHiringService from 'erp/recruitment/hiring/useHiringService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';

type CancelHiringButtonProps = {
  permissionToCheck: string,
};

const CancelHiringButton = (props: CancelHiringButtonProps) => {
  const id = useHiringId();
  const { cancelRequest } = useHiringService();

  const handleClosePosition = async () => {
    return cancelRequest(id);
  };

  return <ButtonWithConfirmation
    {...props}
    displayMessage="Do you really want to cancel this request?"
    submitMethod={handleClosePosition}
    afterSubmit={{
      successMessage: 'Request cancelled successfully!',
      errorMessage: "Request couldn't be cancelled!",
    }}
  >
    Cancel
  </ButtonWithConfirmation>;
};

export default CancelHiringButton;
