/* istanbul ignore file */
import React, { FC, useEffect } from 'react';
import D3Tree, { CustomNodeElementProps, RawNodeDatum } from 'react-d3-tree';
import TreeNode from './TreeNode';
import useTreeHelper from './useTreeHelper';
import { getDefaultZoom, TreeProps } from './treeHelpers';
import Loading from 'shared/uibuilder/Loading';
import './index.scss';

const nodeSize = { x: 300, y: 240 };

const Tree: FC<TreeProps> = ({ data, nodeChildren, onNodeClick, height }) => {
  const defaultZoom = getDefaultZoom(data);
  const {
    translateData,
    zoom,
    treeContainer,
    initInitialSettings,
    onToggleNode,
    onChangeNode,
    checkNodeState,
    collapsedNodes,
    treeRef,
    updateTreeState,
  } = useTreeHelper({ defaultZoom });

  useEffect(() => {
    initInitialSettings();
    // Suppressed warnings because we only need to call useEffect callback if data or treeContainer are updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeContainer?.current, data]);

  useEffect(() => {
    updateTreeState();
    // Suppressed warnings because we only need to call useEffect callback if collapsedNodes are updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedNodes]);

  if (data.length) {
    return (
      <div ref={treeContainer} style={{ height }}>
        {collapsedNodes ? (
          <div className="graph" style={{ height }}>
            <D3Tree
              data={data as RawNodeDatum[]}
              renderCustomNodeElement={(rd3tNodeProps: CustomNodeElementProps) => (
                <TreeNode
                  {...rd3tNodeProps}
                  nodeChildren={nodeChildren}
                  onClick={onNodeClick}
                  onToggleNode={onToggleNode}
                  onChangeNode={onChangeNode}
                  checkNodeState={checkNodeState}
                />
              )}
              translate={translateData}
              zoom={zoom}
              orientation="vertical"
              pathFunc="step"
              nodeSize={nodeSize}
              scaleExtent={{ min: 0.35, max: 2 }}
              separation={{ siblings: 1.2, nonSiblings: 1.75 }}
              ref={treeRef}
            />
          </div>
        ) : (
          <div className="tree-loader-wrapper">
            <Loading />
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default Tree;
