/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import Show from 'shared/uibuilder/show/Show';
import useProjectService from 'erp/project/shared/projectService';
import List from 'shared/uibuilder/list/List';
import { FormattedDateField } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import useProjectWeeklyReportsService, {
  ProjectWeeklyReportStatusLabels,
} from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import { useParams } from 'react-router-dom';
import ProjectEntityHeader from 'erp/project/shared/ProjectEntityHeader';
import ViewProjectPageMenu from 'erp/project/Show/ViewProjectPageMenu';
import ProjectReportsBreadcrumbs from 'erp/project/weeklyreports/shared/ProjectWeeklyReportsBreadcrumbs';
import ProjectReportLinkField from 'erp/project/weeklyreports/shared/field/ProjectWeeklyReportLinkField';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import EnumField from 'shared/uibuilder/field/EnumField';
import ArtifactStorageOneFileLink from 'artifactStorage/shared/field/ArtifactStorageOneFileLink';

const ProjectWeeklyReportList = () => {
  const { id: projectId } = useParams<Dictionary<string>>();
  const { search } = useProjectWeeklyReportsService();
  const { getById } = useProjectService();

  return (
    <Show getDataMethod={() => getById(projectId)}>
      <ShowPageLayout
        entityHeader={<ProjectEntityHeader />}
        menu={<ViewProjectPageMenu />}
        breadcrumbs={<ProjectReportsBreadcrumbs />}
      >
        <List getDataMethod={search}>
          <RelationListLayout header="Weekly Report">
            <DataGridLayout isSmallTable showTotal={false}>
              <ProjectReportLinkField
                source="id"
                sourceDateFrom="from"
                sourceDateTo="to"
                label="Weekly cycle period"
                width="40%"
              />
              <FormattedDateField source="updatedAt" format={DATE_FORMAT.FULL_DATE} label="Modification date" />
              <EnumField label="Status" source="status" options={ProjectWeeklyReportStatusLabels} />
              <ArtifactStorageOneFileLink label="Report" source="report.id" linkText="View report" width="150px" />
            </DataGridLayout>
          </RelationListLayout>
        </List>
      </ShowPageLayout>
    </Show>
  );
};

export default ProjectWeeklyReportList;
