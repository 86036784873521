import { useResourceManagementApi } from 'api';
import useCrudService from 'shared/crud';
import { useCallback } from 'react';
import { ResourceData } from '../../shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import trainingsValidation from './createupdate/trainingsValidation';

export const READ_TRAINING_DOMAIN = 'COMPANY_READ_TRAINING_DOMAIN';
export const WRITE_TRAINING_DOMAIN = 'COMPANY_WRITE_TRAINING_DOMAIN';

const RESOURCE_URL = '/trainings';

const useTrainingsService = () => {
  const { sendPutRequest } = useResourceManagementApi();

  const {
    search: baseSearch,
    create: initialCreate,
    update: initialUpdate,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useResourceManagementApi,
  });

  const mappingRequestData = useCallback((data: ResourceData) => {
    return {
      ...data,
      competencies: data.competencies.map((value: string) => ({ name: value })),
    };
  }, []);

  const search = useCallback(
    (request: ResourceData) => {
      const params = cloneDeep(request);

      const searchString = params.filter?.['specification:search']?.eq || '';

      if (searchString && params.filter) {
        delete params.filter?.['specification:search'];
        params.filter.name = {
          ct: searchString,
        };
      }

      ['isInternal'].forEach(source => {
        const filterValue = params.filter?.[source]?.eq || '';

        if (filterValue && params.filter) {
          params.filter[source] = {
            eq: filterValue === 'YES',
          };
        }
      });

      return baseSearch(params);
    },
    [baseSearch],
  );

  const create = useCallback(
    (data: ResourceData) => {
      return initialCreate(mappingRequestData(data));
    },
    [initialCreate, mappingRequestData],
  );

  const update = useCallback(
    (_id: StringOrNumber, data: ResourceData) => {
      return sendPutRequest(RESOURCE_URL, mappingRequestData(data));
    },
    [mappingRequestData, sendPutRequest],
  );

  const getValidationSchema = () => Promise.resolve(trainingsValidation);

  return {
    ...baseCrud,
    update,
    search,
    create,
    getValidationSchema,
  };
};

export default useTrainingsService;
