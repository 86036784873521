import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import EnumField from 'shared/uibuilder/field/EnumField';
import { HiringRequest, NECESSITY_STATUS_OPTIONS, POSITION_STATUS_OPTIONS } from '../../useHiringService';
import DateField from 'shared/uibuilder/field/DateField';
import CloseHiringPositionButton from '../button/CloseHiringPositionButton';
import ReopenHiringPositionButton from '../button/ReopenHiringPositionButton';

const PositionsListFields = ({ source, isShowButton = true }: { source: string; isShowButton?: boolean }) => {
  const { data, setData = () => {} } = useShowContext();
  const allShowData = data.getData();
  const requestPositions = data.getValueBySource(source);

  return (
    <ControlledList data={{ items: requestPositions, totalPages: 1, totalElements: requestPositions?.length }}>
      <DataGridLayout
        isSmallTable
        buttons={[
          <ReopenHiringPositionButton allShowData={allShowData as HiringRequest} parentShowSetData={setData} />,
          <CloseHiringPositionButton
            isVisible={isShowButton}
            allShowData={allShowData as HiringRequest}
            parentShowSetData={setData}
          />,
        ]}
      >
        <EnumField options={NECESSITY_STATUS_OPTIONS} source="necessityStatus" label="Type" width="35%" />
        <EnumField options={POSITION_STATUS_OPTIONS} source="positionStatus" label="Status" width="25%" />
        <DateField source="forecastDate" label="Forecast date" width="25%" />
        <DateField source="closeDate" label="Closed date" width="25%" />
      </DataGridLayout>
    </ControlledList>
  );
};

export default PositionsListFields;
