/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CandidateLink = ({ name, id }: { name?: string; id: number | string }) => {
  const { getSingleEntityUrl } = useCandidateUrl();

  return (
    <Link to={getSingleEntityUrl(id)} target="_blank">
      {name || id}
    </Link>
  );
};

export default CandidateLink;
