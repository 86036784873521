/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
// components
import DeleteButton from 'uibuilder/button/DeleteButton';
// services
import useLeadService from 'crm/lead/shared/leadService';
import { useLeadUrl } from 'crm/lead/LeadRouter';

const deleteMessage = 'Do you really want to delete this lead?';

const DeleteLeadButton = () => {
  const { id: leadId } = useParams<Dictionary<string>>();
  const { deleteById } = useLeadService();
  const { getListUrl } = useLeadUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(leadId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Lead has been successfully deleted.',
        errorMessage: `Can't delete lead`,
      }}
    />
  );
};

export default DeleteLeadButton;
