/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { DateField, EmailField, HtmlField, ListField, TextField } from 'shared/uibuilder/field';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import { useOpportunityId, useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import OpportunityEntityHeader from 'crm/opportunity/shared/OpportunityEntityHeader';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';
import useOpportunityService, {
  CLOSED_LOST,
  CLOSED_WON,
  DELETE_OPPORTUNITY,
  UPDATE_OPPORTUNITY,
} from 'crm/opportunity/shared/opportunityService';
import OpportunityStageField from 'crm/opportunity/shared/field/OpportunityStageField';
import SingleOpportunityBreadcrumbs from 'crm/opportunity/shared/SingleOpportunityBreadcrumbs';
import { LeadLinkField } from 'crm/lead';
import ContactLinkField from 'crm/contact/shared/field/ContactLinkField';
import AmountField from 'shared/uibuilder/field/AmountField';
import OpportunityTypeField from 'crm/opportunity/shared/field/OpportunityTypeField';
import OpportunityProbabilityField from 'crm/opportunity/shared/field/OpportunityProbabilityField';
import OpportunityMenu from 'crm/opportunity/shared/OpportunityMenu';
import OpportunityExpectedRevenueField from 'crm/opportunity/shared/field/OpportunityExpectedRevenueField';
import EditOpportunityButton from 'crm/opportunity/shared/button/EditOpportunityButton';
import DeleteButton from 'uibuilder/button/DeleteButton';
import OpportunityPrimaryContactPhoneField from 'crm/opportunity/shared/field/OpportunityPrimaryContactPhoneField';
import ClosedReasonField from '../shared/field/ClosedReasonField';
import useFeatureToggle, { Features } from 'featuretoggle';
import BooleanOptionField from 'shared/uibuilder/field/BooleanOptionField';
import OpportunityPipeline from 'crm/opportunity/shared/OpportunityPipeline';
import OpportunityLinkField from 'crm/opportunity/shared/field/OpportunityLinkField';

const OpportunityViewPage = () => {
  const id = useOpportunityId();
  const { getById, deleteById } = useOpportunityService();
  const { getListUrl } = useOpportunityUrl();
  const { isFeatureEnabled } = useFeatureToggle();

  const buttons = [
    <EditOpportunityButton permissionToCheck={UPDATE_OPPORTUNITY} icon={<i className="fa fa-edit" />} />,
    <DeleteButton
      deleteMethod={() => deleteById(id)}
      displayMessage="Do you really want to delete this opportunity?"
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Opportunity has been successfully deleted.',
        errorMessage: `Can't delete opportunity`,
      }}
      permissionToCheck={DELETE_OPPORTUNITY}
    />,
  ];

  const isClosedReasonVisible = (opportunity: any) =>
    isFeatureEnabled(Features.OPPORTUNITY_CLOSED_REASON) &&
    (opportunity.stage === CLOSED_WON || opportunity.stage === CLOSED_LOST);

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        headerButtons={buttons}
        menu={<OpportunityMenu />}
        breadcrumbs={<SingleOpportunityBreadcrumbs />}
        entityHeader={<OpportunityEntityHeader />}
        hasSeparateSections
      >
        <ShowSection isVisible={data => data.getValueBySource('crmPipeline')}>
          <OpportunityPipeline />
        </ShowSection>
        <ShowSection title="Opportunity Information">
          <SectionRow>
            <AccountLinkField label="Account Name" idSource="accountId" source="accountName" isInternal={false} />
            <OpportunityTypeField label="Opportunity Type" source="type" />
            <OpportunityStageField label="Opportunity State" source="stateStep" />
            <OpportunityProbabilityField label="Probability" source="stateStep" />
            <OpportunityLinkField
              source="afterDiscoveryOpportunityName"
              idSource="afterDiscoveryOpportunityId"
              label="After Discovery Opportunity"
              isVisible={(data: any) => data.getValueBySource('afterDiscoveryOpportunityId')}
            />
            <OpportunityLinkField
              source="discoveryOpportunityName"
              idSource="discoveryOpportunityId"
              label="Discovery Opportunity"
              isVisible={(data: any) => data.getValueBySource('discoveryOpportunityId')}
            />
            <DateField label="Close Date" source="closeDate" />
            <DateField
              label="Postponed Until"
              source="postponedUntil"
              isVisible={(data: any) => data.getValueBySource('postponedUntil')}
            />
            <TextField label="Opportunity Owner" source="ownerName" />
            <BooleanOptionField source="isContractRenewal" label="Contract Renewal" />
            <LeadLinkField
              label="Converted From"
              idSource="convertedFromId"
              nameSource="convertedFromName"
              isVisible={(data: any) => data.getValueBySource('convertedFromId')}
            />
            <ClosedReasonField
              isVisible={isClosedReasonVisible}
              label="Primary Reason for Closed Status"
              source="closedReason.primaryReason"
            />
            <ClosedReasonField
              isVisible={isClosedReasonVisible}
              label="Secondary Reason for Closed Status"
              source="closedReason.secondaryReason"
            />
          </SectionRow>
          <BigSectionRow>
            <ReplaceUrlWithLink isVisible={isClosedReasonVisible}>
              <HtmlField label="Closed Summary" source="closedReason.summary" />
            </ReplaceUrlWithLink>
          </BigSectionRow>
        </ShowSection>

        <ShowSection title="Contact Information">
          <SectionRow>
            <ContactLinkField
              label="Primary Contact"
              linkText={(data: any) =>
                data.getValueBySource('primaryContact') && data.getValueBySource('primaryContact.fullName')
              }
              idSource="primaryContact.id"
              isInternal={false}
            />
            <OpportunityPrimaryContactPhoneField
              label="Phone Numbers"
              phoneNumberSource="primaryContact.phoneNumber"
              mobilePhoneNumberSource="primaryContact.mobilePhoneNumber"
            />
            <ListField source="primaryContact.emails">
              <EmailField />
            </ListField>
          </SectionRow>
        </ShowSection>

        <ShowSection title="Financial Information">
          <SectionRow>
            <AmountField label="Amount (USD)" source="amount" />

            <OpportunityExpectedRevenueField label="Expected Revenue (USD)" stageSource="stage" amountSource="amount" />
          </SectionRow>
        </ShowSection>

        <ShowSection title="Description Information">
          <BigSectionRow>
            <ReplaceUrlWithLink>
              <HtmlField label="" source="description" />
            </ReplaceUrlWithLink>
          </BigSectionRow>
        </ShowSection>

        <ShowSection title="Details for presale phase" isVisible={data => data.getValueBySource('preSaleInput')}>
          <BigSectionRow>
            <ReplaceUrlWithLink>
              <HtmlField label="" source="preSaleInput" />
            </ReplaceUrlWithLink>
          </BigSectionRow>
        </ShowSection>

        <ShowSection title="Presale output" isVisible={data => data.getValueBySource('preSaleOutput')}>
          <BigSectionRow>
            <ReplaceUrlWithLink>
              <HtmlField label="" source="preSaleOutput" />
            </ReplaceUrlWithLink>
          </BigSectionRow>
        </ShowSection>

        <ShowSection title="Additional Information">
          <SectionRow>
            <TextField label="Next Step" source="nextStep" />
            <DateField label="Next Step Deadline" source="nextStepDeadline" />
          </SectionRow>
        </ShowSection>

        <ShowSection title="Attachments">
          <BigSectionRow>
            <AttachmentsListField label="" source="attachmentsIds" />
          </BigSectionRow>
        </ShowSection>
        <ShowSection
          title="Attachments for presale"
          isVisible={data => {
            const relatedData = data.getValueBySource('preSaleInputAttachmentsIds');
            return relatedData && relatedData.length > 0;
          }}
        >
          <BigSectionRow>
            <AttachmentsListField label="" source="preSaleInputAttachmentsIds" />
          </BigSectionRow>
        </ShowSection>
        <ShowSection
          title="Presale result attachments"
          isVisible={data => {
            const relatedData = data.getValueBySource('preSaleOutputAttachmentsIds');
            return relatedData && relatedData.length > 0;
          }}
        >
          <BigSectionRow>
            <AttachmentsListField label="" source="preSaleOutputAttachmentsIds" />
          </BigSectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default OpportunityViewPage;
