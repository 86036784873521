import React from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

export const MESSAGE = 'Are you sure you want to change candidate? Your changes will be lost.';

const ConvertCandidatePrompt = ({ candidateId }: { candidateId?: number }) => {
  const { search, pathname } = useLocation();
  const { isDirty } = useFormContext();

  // dont need to render additional prompt, because only one prompt can be on page
  if (!candidateId) {
    return null;
  }

  return (
    <Prompt
      // @ts-ignore
      message={({ search: newSearch, pathname: newPathname }) => {
        const isCandidateChanged = search !== newSearch && pathname === newPathname;
        if (isDirty || isCandidateChanged) {
          return MESSAGE;
        }

        return null;
      }}
    />
  );
};

export default ConvertCandidatePrompt;
